import { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { ROUTE } from '~/views/routes/config'
import NavMenuData from './NavMenuData'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
export default function NavbarLeft() {
  const [locationKeys, setLocationKeys] = useState([])
  const history = useHistory()
  const location = useLocation()
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const { actions: actionTheme } = useTheme()
  useEffect(() => {
    actionTheme?.onMobileNavToggle(false)
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          // eslint-disable-next-line
          setLocationKeys(([_, ...keys]) => keys)

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys])

          // Handle back event
        }
      }
    })
  }, [locationKeys, location])

  return (
    <div className="flex navbar__left" key={'NavbarLeft'}>
      <div className="w-[115px] h-[60px]">
        <Link
          to={ROUTE?.HOME?.PATH}
          className={`navbar__left_element h-full w-full`}
        >
          <img
            src={`${process.env.PUBLIC_URL}/imgs/common/reMonsterLogo.png`}
            alt=""
          />
        </Link>
      </div>
      {!isMobile && <NavMenuData />}
    </div>
  )
}
