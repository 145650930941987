import { useEffect, useState } from 'react'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import {
  checkTransactionConfirmation,
  hexToNumberString,
  numberToHex,
} from '~/blockchain/provider'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyShop from '~/components/shared-components/BodyShop'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { useShop } from '~/views/redux/hooks/useShop'
import configs from '~/configs'
import { ifaceContractShop } from '~/blockchain/contract'
import TransactionCompletedShop from '~/components/Modals/TransactionCompletedShop'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import ShopBITCard from '../components/ShopBITCard'
import { useSDK } from '@metamask/sdk-react'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'

export const BitShop = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  const { actions } = useShop()
  const { actions: actionsCommon } = useCommon()
  const [dataBIT, setDataBIT] = useState()
  const [tema, setTema] = useState(1)
  const [loadingModal, setLoadingModal] = useState(false)
  const [txHash, setTxHash] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const {
    actions: actionTheme,
    data: { user },
  } = useTheme()

  const { actions: actionsProfile } = useAccountProfile()
  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }

  const handleBuyBITShop = async (
    item,
    quantity,
    closeModalCheckout = null,
  ) => {
    try {
      setLoadingModal(true)
      const data = {
        address: user?.address_wallet,
        typeAsset: 'bit',
        group: 'a',
        amount: Number(quantity),
      }
      actions.signBuyShop(data, (res) => {
        buyBIT(
          res.typeAsset,
          res.address,
          0,
          res.price,
          Number(quantity),
          res.deadline,
          res.signature,
          closeModalCheckout,
        )
      })
    } catch (error) {
      setLoadingModal(false)
      // eslint-disable-next-line
      console.log(error)
    }
  }
  const buyBIT = async (
    typeAsset,
    address,
    group,
    priceInWei,
    number,
    deadline,
    sig,
    closeModalCheckout = null,
  ) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractShop.encodeFunctionData('buyItem', [
            typeAsset,
            address,
            0,
            group,
            priceInWei,
            number,
            deadline,
            sig,
          ]),
          to: configs.ADDRESS_SHOP,
          value: numberToHex(priceInWei),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then(async (r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            await sleep(3000)
            setLoadingModal(false)
            if (closeModalCheckout) {
              closeModalCheckout()
            }
            setTxHash(transaction?.hash)
            showModalSuccess()
            actionsProfile.getAccountProfile((res) => {
              actionTheme.updateInfoUser({
                ...res?.player_info,
              })
            })
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        });
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: address,
                data: ifaceContractShop.encodeFunctionData('buyItem', [
                  typeAsset,
                  address,
                  0,
                  group,
                  priceInWei,
                  number,
                  deadline,
                  sig,
                ]),
                to: configs.ADDRESS_SHOP,
                value: numberToHex(priceInWei),
                nonce
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then(async(r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                await sleep(3000)
                setLoadingModal(false)
                if (closeModalCheckout) {
                  closeModalCheckout()
                }
                setTxHash(txHash)
                showModalSuccess()
                actionsProfile.getAccountProfile((res) => {
                  actionTheme.updateInfoUser({
                    ...res?.player_info,
                  })
                })
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      setLoadingModal(false)
      // eslint-disable-next-line
      console.error(error)
    }
  }

  const loadBIT = async () => {
    actions.getDataShop({}, (res) => {
      let data = [
        {
          name: 'BIT',
          img: '../imgs/coins/bit.svg',
          price: res[3]?.price,
          type: 'item',
        },
      ]
      setDataBIT(data)
    })
  }

  useEffect(() => {
    actionsCommon.getTema({}, (res) => {
      setTema(res?.tema)
    })
    loadBIT()
  }, [])
  return (
    <BodyShop>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="">
          <div className="title__shop mb-10">{t('shop.bit').toUpperCase()}</div>
        </div>
        <div className="flex flex-wrap gap-5">
          {dataBIT?.map((data, index) => (
            <ShopBITCard
              t={t}
              key={index}
              item={data}
              handleConfirm={handleBuyBITShop}
              loadingModal={loadingModal}
              tema={tema}
            />
          ))}
        </div>
      </div>
      <TransactionCompletedShop
        action={{
          isModalOpen: isModalSuccessOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccess,
        }}
        t={t}
        name={dataBIT ? `${dataBIT[0]?.name}` : ''}
        store={'Shop'}
        txHash={txHash}
      />
    </BodyShop>
  )
}

export default BitShop
