import { Link } from 'react-router-dom'
import { convertImageMonsterSeed } from '~/common/constants'
import { ROUTE } from '~/views/routes/config'

export const CardTree = (props) => {
  const { data } = props
  const isMemory = data?.is_memory
  const linkPath = isMemory
    ? `${ROUTE.MONSTER_MEMORY_DETAIL.SUB_PATH}/${data?.monster_nft_id}`
    : `${ROUTE.MONSTER_DETAIL.SUB_PATH}/${data?.monster_nft_id}`

  return (
    // <Link to={linkPath}>
    //   <div
    //     className={`flex items-center justify-center ${
    //       data?.seedTrees?.length === 1 && 'no__parr'
    //     }`}
    //   >
    //     <div className="relative w-[4rem] h-[4rem] md:w-[152px] md:h-[152px] border-4 border-[#1890ff] rounded-[8px] rotate-180">
    //       <img
    //         className="w-full h-full object-contain rounded-[3px]"
    //         alt=""
    //         src={`${
    //           process.env.PUBLIC_URL
    //         }/imgs/monsterSeed/${convertImageMonsterSeed(
    //           data?.monster_script_id,
    //         )}.png`}
    //       />
    //       <div className="absolute top-0">
    //         <img
    //           className="w-[1.5rem] h-[1.5rem] sm:w-[40px] sm:h-[40px]"
    //           style={{ objectFit: 'contain' }}
    //           src={`../svg/rankTag/${data?.monster_rank}.svg`}
    //           alt=""
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </Link>
    <a href={linkPath} target="_blank" rel="noopener noreferrer">
      <div
        className={`flex items-center justify-center ${
          data?.seedTrees?.length === 1 && 'no__parr'
        }`}
      >
        <div className="relative w-[4rem] h-[4rem] md:w-[152px] md:h-[152px] border-4 border-[#1890ff] rounded-[8px] rotate-180">
          <img
            className="w-full h-full object-contain rounded-[3px]"
            alt=""
            src={`${
              process.env.PUBLIC_URL
            }/imgs/monsterSeed/${convertImageMonsterSeed(
              data?.monster_script_id,
            )}.png`}
          />
          <div className="absolute top-0">
            <img
              className="w-[1.5rem] h-[1.5rem] sm:w-[40px] sm:h-[40px]"
              style={{ objectFit: 'contain' }}
              src={`../svg/rankTag/${data?.monster_rank}.svg`}
              alt=""
            />
          </div>
        </div>
      </div>
    </a>
  )
}
