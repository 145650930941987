import React, { useCallback, useEffect, useState } from 'react'
import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Dropdown, Pagination, Row, Spin } from 'antd'
import {
  FARM_SORT_USER,
  FARM_SORT_USER_JA,
  SCREEN_SIZE,
} from '~/common/constants'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { FarmsCard } from '~/components/shared-components/Card/FarmsCard'
import {
  ContractFarm,
  ifaceContractFarm,
  ifaceContractMarketplace,
} from '~/blockchain/contract'
import SellModal from '~/components/Modals/SellModal'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import configs from '~/configs'
import {
  checkTransactionConfirmation,
  getBalanceOAS,
  hexToNumberString,
  toWei,
} from '~/blockchain/provider'
import { useFarm } from '~/views/redux/hooks/useFarm'
// import { useQueryState } from '~/common/hooks/useQueryState'
import TransactionCompletedSell from '~/components/Modals/TransactionCompletedSell'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { NodataMonster } from '~/components/shared-components/NoData/NodataMonster'
import { FilterFarm } from '~/views/app/Farm/components/FilterFarm'
import { useSDK } from '@metamask/sdk-react'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useMediaQuery } from 'react-responsive'

export const AssetsFarms = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  const [sort, setSort] = useState(
    JSON.parse(localStorage.getItem('farmsMyAssets'))?.sort ||
      FARM_SORT_USER[0],
  )
  const [typeSort, setTypeSort] = useState('-1')
  const {
    data: { user },
  } = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingModal, setLoadingModal] = useState(false)
  const [nftActive, setNftActive] = useState()
  const [txHash, setTxHash] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  // farm
  const [terrainCompatibility, setTerrainCompatibility] = useState(
    JSON.parse(localStorage.getItem('farmsMyAssets'))?.terrainCompatibility ||
      [],
  )
  const [boostParameter, setBoostParameter] = useState(() => {
    const storedData = localStorage.getItem('farmsMyAssets')
    return storedData
      ? JSON.parse(storedData)?.boostParameter
      : {
          health: [],
          strength: [],
          intelligent: [],
          dexterity: [],
          agility: [],
          vitality: [],
          fatigue: [],
          stress: [],
          rest: [],
        }
  })
  const [boostPotensial, setBoostPotensial] = useState(() => {
    const storedData = localStorage.getItem('farmsMyAssets')
    return storedData
      ? JSON.parse(storedData)?.boostPotensial
      : {
          health: [],
          strength: [],
          intelligent: [],
          dexterity: [],
          agility: [],
          vitality: [],
          fatigue: [],
          stress: [],
          rest: [],
        }
  })

  const [specialTrainning, setSpecialTrainning] = useState(() => {
    const storedData = localStorage.getItem('farmsMyAssets')
    return storedData
      ? JSON.parse(storedData)?.specialTrainning
      : {
          main_parameter: [],
          sub_parameter: [],
          decrement_parameter: [],
        }
  })

  const [farmTrait, setFarmTrait] = useState(
    JSON.parse(localStorage.getItem('farmsMyAssets'))?.farmTrait || [],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('farmsMyAssets'))?.pageSize || 10,
  )
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('farmsMyAssets'))?.page || 1,
  )
  const {
    actions,
    data: { allFarmsUser, totalFarmsUser, isLoading },
  } = useFarm()
  // const { page, pageSize, setPage, setPageSize } = useQueryState()
  const [balanceOAS, setBalanceOAS] = useState(0)

  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }

  const showModalSell = () => {
    setIsModalOpen(true)
  }

  const handleSell = async (tokenID, price) => {
    try {
      setLoadingModal(true)
      // eslint-disable-next-line
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let allowance = await ContractFarm.getApproved(tokenID)
        if (
          allowance.toLowerCase() === configs.ADDRESS_MARKETPLACE.toLowerCase()
        ) {
          sell(tokenID, price)
        } else {
          let data = {
            from: providerOAS.accounts[0],
            data: ifaceContractFarm.encodeFunctionData('approve', [
              configs.ADDRESS_MARKETPLACE,
              tokenID,
            ]),
            to: configs.ADDRESS_FARM,
            nonce: nonce,
            gasLimit: 21000,
          }

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          })

          const transaction = await ethersWeb3Provider.sendTransaction(
            String(signedTransaction),
          )
          checkTransactionConfirmation(transaction?.hash).then((r) => {
            if (r === 'confirmed') {
              // eslint-disable-next-line
              console.log('Approve:', r, transaction?.hash)
              sell(tokenID, price)
            }
          })
        }
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        let allowance = await ContractFarm.getApproved(tokenID)
        console.log(allowance)
        if (
          allowance.toLowerCase() === configs.ADDRESS_MARKETPLACE.toLowerCase()
        ) {
          sell(tokenID, price)
        } else {
          let nonce = await sdk.getProvider().request({
            method: 'eth_getTransactionCount',
            params: [user.address_wallet, 'latest'],
          })
          sdk
            .getProvider()
            .request({
              method: 'eth_sendTransaction',
              params: [
                {
                  from: user.address_wallet,
                  data: ifaceContractFarm.encodeFunctionData('approve', [
                    configs.ADDRESS_MARKETPLACE,
                    tokenID,
                  ]),
                  to: configs.ADDRESS_FARM,
                  nonce,
                },
              ],
            })
            .then((txHash) => {
              checkTransactionConfirmation(txHash).then((r) => {
                if (r === 'confirmed') {
                  // eslint-disable-next-line
                  console.log('Approve:', r, txHash)
                  sell(tokenID, price)
                }
              })
            })
            .catch((error) => {
              setLoadingModal(false)
              // eslint-disable-next-line
              console.error(error)
            })
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }

  const sell = async (tokenID, price) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'createMarketItemSale',
            [configs.ADDRESS_FARM, tokenID, toWei(price), 1],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccess()
          }
        })
      } else {
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'createMarketItemSale',
                  [configs.ADDRESS_FARM, tokenID, toWei(price), 1],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccess()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setLoadingModal(false)
  }
  const handleChangeSort = (e) => {
    setSort(FARM_SORT_USER[e.key])
  }

  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }

  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      pageSize: pageSize,
      wallet_address: user.address_wallet,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      page,
      farm_terrain: terrainCompatibility?.map((data) => data?.value),
      boost_parameter: boostParameter,
      boost_potensial: boostPotensial,
      main_parameter: specialTrainning['main_parameter'],
      sub_parameter: specialTrainning['sub_parameter'],
      decrement_parameter: specialTrainning['decrement_parameter'],
      farm_trait_id: farmTrait,
    }
    actions.getAllFarmUser(params)
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    terrainCompatibility,
    boostParameter,
    boostPotensial,
    specialTrainning,
    farmTrait,
  ])

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }

  useEffect(() => {
    if (user) {
      refreshData()
      loadBalanceOAS()
    }
  }, [
    txHash,
    page,
    pageSize,
    sort,
    typeSort,
    terrainCompatibility,
    boostParameter,
    boostPotensial,
    specialTrainning,
    farmTrait,
  ])
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterFarm, setOpenFilterFarm] = useState(false)
  const showDrawer = () => {
    setOpenFilterFarm(true)
  }

  const onClose = () => {
    setOpenFilterFarm(false)
  }
  const clearAll = () => {
    setTerrainCompatibility([])
    setBoostParameter({
      health: [],
      strength: [],
      intelligent: [],
      dexterity: [],
      agility: [],
      vitality: [],
      fatigue: [],
      stress: [],
      rest: [],
    })
    setBoostPotensial({
      health: [],
      strength: [],
      intelligent: [],
      dexterity: [],
      agility: [],
      vitality: [],
      fatigue: [],
      stress: [],
      rest: [],
    })
    setSpecialTrainning({
      main_parameter: [],
      sub_parameter: [],
      decrement_parameter: [],
    })
    setFarmTrait([])
  }
  useEffect(() => {
    if (page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    pageSize,
    sort,
    typeSort,
    terrainCompatibility,
    boostParameter,
    boostPotensial,
    specialTrainning,
    farmTrait,
  ])
  const pageFilter = JSON.parse(localStorage.getItem('farmsMyAssets'))?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])
  return (
    <BodyProfile>
      <Row className={`${isMobile ? '' : 'mx-auto h-[100vh]'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterFarm
                sort={sort}
                page={page}
                pageSize={pageSize}
                terrainCompatibility={terrainCompatibility}
                boostParameter={boostParameter}
                boostPotensial={boostPotensial}
                specialTrainning={specialTrainning}
                farmTrait={farmTrait}
                setTerrainCompatibility={setTerrainCompatibility}
                setBoostParameter={setBoostParameter}
                setBoostPotensial={setBoostPotensial}
                setSpecialTrainning={setSpecialTrainning}
                setFarmTrait={setFarmTrait}
                classCustom="filter__profile"
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0 relative md:sticky top-0 sm:top-[104px]  pt-[1.5rem] pb-12">
            <div className="text-[24px] mb-4 uppercase">
              {t('profile.menu.farms')}
            </div>

            <div className="flex justify-between flex-wrap mb-4">
              <div className="text-[24px]">
                {t('countFarm', {
                  total: totalFarmsUser,
                })}
                {totalFarmsUser > 1 ? t('more') : ''}
              </div>
              {isMobile ? (
                <div className="flex items-center text-[16px] gap-2">
                  <Dropdown
                    className="flex items-center cursor-pointer sort__value"
                    menu={{
                      items:
                        user?.player_language === 'en'
                          ? FARM_SORT_USER
                          : FARM_SORT_USER_JA,
                      selectable: true,
                      onClick: handleChangeSort,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                      <div className="title">
                        {' '}
                        {t(`common.filter.sort.${sort?.valueLanguage}`)}
                      </div>
                    </Button>
                  </Dropdown>

                  <Button
                    className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                    onClick={() => {
                      handleChangeTypeSort()
                    }}
                  >
                    <ArrowUpOutlined
                      style={{ fontSize: 18 }}
                      rotate={typeSort === '1' ? 0 : 180}
                    />
                  </Button>
                  <div>
                    <Button
                      type="primary"
                      onClick={showDrawer}
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                    >
                      <FilterOutlined />
                      {t('filter')}
                    </Button>
                    <Drawer
                      title={t('filter')}
                      width={330}
                      placement="left"
                      onClose={onClose}
                      open={openFilterFarm}
                      extra={
                        <div
                          className="text-[16px] text-[#FAAD14] cursor-pointer"
                          onClick={() => {
                            clearAll()
                          }}
                        >
                          {t('clearAll')}
                        </div>
                      }
                    >
                      <div className="flex w-full h-full">
                        <FilterFarm
                          sort={sort}
                          page={page}
                          pageSize={pageSize}
                          terrainCompatibility={terrainCompatibility}
                          setTerrainCompatibility={setTerrainCompatibility}
                          boostParameter={boostParameter}
                          setBoostParameter={setBoostParameter}
                          boostPotensial={boostPotensial}
                          setBoostPotensial={setBoostPotensial}
                          specialTrainning={specialTrainning}
                          setSpecialTrainning={setSpecialTrainning}
                          farmTrait={farmTrait}
                          setFarmTrait={setFarmTrait}
                          classCustom="filter__profile"
                        />
                      </div>
                    </Drawer>
                  </div>
                </div>
              ) : (
                <div className="flex items-center text-[16px] gap-2">
                  <div> {t('sortBy')}</div>

                  <Dropdown
                    className="flex items-center cursor-pointer ml-3  sort__value"
                    menu={{
                      items:
                        user?.player_language === 'en'
                          ? FARM_SORT_USER
                          : FARM_SORT_USER_JA,
                      selectable: true,
                      onClick: handleChangeSort,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                      <div className="title">
                        {' '}
                        {t(`common.filter.sort.${sort?.valueLanguage}`)}
                      </div>
                    </Button>
                  </Dropdown>

                  <Button
                    className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                    onClick={() => {
                      handleChangeTypeSort()
                    }}
                  >
                    <ArrowUpOutlined
                      style={{ fontSize: 18 }}
                      rotate={typeSort === '1' ? 0 : 180}
                    />
                  </Button>
                </div>
              )}
            </div>
            <Spin spinning={isLoading}>
              <div className="flex flex-wrap justify-center gap-4">
                {allFarmsUser?.map((data, i) => (
                  <FarmsCard
                    key={i?.toString()}
                    data={data}
                    button={{
                      title: 'Sell',
                      functionButton: () => {
                        setNftActive(data)
                        showModalSell()
                      },
                    }}
                  />
                ))}
              </div>
            </Spin>
            {!isLoading && totalFarmsUser === 0 && <NodataMonster />}

            {totalFarmsUser > 0 && (
              <div className="pagination__common">
                <Pagination
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                  className="mt-5"
                  size="small"
                  locale={{ jump_to: 'Go to', page: '' }}
                  total={totalFarmsUser}
                  // defaultCurrent={page}
                  pageSize={pageSize}
                  current={page}
                  showSizeChanger
                  showQuickJumper
                  showTitle
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <SellModal
        action={{
          isModalOpen: isModalOpen,
          handleOk: handleSell,
          handleCancel: handleCancel,
        }}
        t={t}
        tokenID={nftActive?.farm_nft_id}
        loadingModal={isLoadingModal}
        name={`${nftActive?.farm_name} (#${nftActive?.farm_nft_id})`}
        // tema={tema}
        balance={balanceOAS}
      />
      <TransactionCompletedSell
        action={{
          isModalOpen: isModalSuccessOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccess,
        }}
        t={t}
        name={`${nftActive?.farm_name} (#${nftActive?.farm_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
    </BodyProfile>
  )
}
export default AssetsFarms
