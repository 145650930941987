export const ROUTE = {
  //version
  VERSION: {
    PATH: '/version',
    TITLE: 'version',
  },
  //Common
  MARKETPLACE: {
    PATH: '/',
    TITLE: 'marketplace',
  },
  HOME: {
    PATH: '/home',
    TITLE: 'monster',
  },
  GAME: {
    PATH: '/Game',
    TITLE: 'Game',
  },
  MONSTER_DETAIL: {
    MAIN_PATH: 'monster',
    SUB_PATH: '/monster',
    PATH: '/monster/:id',
    TITLE: 'monsterDetail',
  },
  MONSTER_MEMORY_DETAIL: {
    MAIN_PATH: 'memory-monster',
    SUB_PATH: '/memory-monster',
    PATH: '/memory-monster/:id',
    TITLE: 'memoryMonsterDetail',
  },
  FARM: {
    PATH: '/farm',
    TITLE: 'farm',
  },
  FARM_DETAIL: {
    MAIN_PATH: 'farm-detail',
    SUB_PATH: '/farm-detail',
    PATH: '/farm-detail/:id',
    TITLE: 'farmDetail',
  },
  ITEM: {
    PATH: '/item',
    TITLE: 'item',
  },
  ITEM_DETAIL: {
    MAIN_PATH: 'item-detail',
    SUB_PATH: '/item-detail',
    PATH: '/item-detail/:id',
    TITLE: 'itemDetail',
  },
  HASH_NFT: {
    PATH: '/hash-nft',
    TITLE: 'hashNFT',
  },
  HASH_NFT_DETAIL: {
    MAIN_PATH: 'hash-nft-detail',
    SUB_PATH: '/hash-nft-detail',
    PATH: '/hash-nft-detail/:id',
    TITLE: 'hash-nftDetail',
  },
  //Shop
  SHOP: {
    PATH: '/shop',
    TITLE: 'shop',
  },
  GENESIS_BOX: {
    PATH: '/genesis-box',
    TITLE: 'genesisBox',
  },
  GENERAL_BOX: {
    PATH: '/general-box',
    TITLE: 'generalBox',
  },
  TRAINING_ITEM: {
    PATH: '/training-item',
    TITLE: 'trainingItem',
  },
  FARM_SHOP: {
    PATH: '/farm-shop',
    TITLE: 'farmShop',
  },
  BIT: {
    PATH: '/BIT',
    TITLE: 'bit',
  },

  //Profile start
  PROFILE_MONSTER_SALES: {
    PATH: '/profile/monsters-sales',
    TITLE: 'monsters',
    MAIN_PATH: 'profile',
  },
  PROFILE_FARMS_SALES: {
    PATH: '/profile/farms-sales',
    TITLE: 'farms',
    MAIN_PATH: 'profile',
  },
  PROFILE_ITEMS_SALES: {
    PATH: '/profile/items-sales',
    TITLE: 'items',
    MAIN_PATH: 'profile',
  },
  PROFILE_HASH_SALES: {
    PATH: '/profile/hash-sales',
    TITLE: 'hash',
    MAIN_PATH: 'profile',
  },

  PROFILE_MONSTERS: {
    PATH: '/profile/monster',
    TITLE: 'monsters',
    MAIN_PATH: 'profile',
  },
  PROFILE_MEMORY_MONSTERS: {
    PATH: '/profile/memory-monster',
    TITLE: 'memory',
    MAIN_PATH: 'profile',
  },
  PROFILE_FARMS: {
    PATH: '/profile/farms',
    TITLE: 'farms',
    MAIN_PATH: 'profile',
  },
  PROFILE_ITEMS: {
    PATH: '/profile/items',
    TITLE: 'items',
    MAIN_PATH: 'profile',
  },
  PROFILE_HASH: {
    PATH: '/profile/hash',
    TITLE: 'hash',
    MAIN_PATH: 'profile',
  },
  PROFILE_GENESIS: {
    PATH: '/profile/genesis',
    TITLE: 'genesis',
    MAIN_PATH: 'profile',
  },
  PROFILE_GENERAL: {
    PATH: '/profile/general',
    TITLE: 'general',
    MAIN_PATH: 'profile',
  },
  PROFILE_REWARDS: {
    PATH: '/profile/rewards',
    TITLE: 'rewards',
    MAIN_PATH: 'profile',
  },
  PROFILE_BRIDGE: {
    PATH: '/profile/bridge',
    TITLE: 'bridge',
    MAIN_PATH: 'profile',
  },
  PROFILE_TRADE_HISTORY: {
    PATH: '/profile/trade-history',
    TITLE: 'tradeHistory',
    MAIN_PATH: 'profile',
  },
  PROFILE_ACCOUNT_SETTINGS: {
    PATH: '/profile/account-settings',
    TITLE: 'accountSettings',
    MAIN_PATH: 'profile',
  },

  //Profile end
}
