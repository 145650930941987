import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getAllMemoryMonsterUserSuccess,
  getAllMemoryMonsterUserFailed,
  GET_ALL_MEMORY_MONSTER_USER_START,
} from '../../actions/monster'

/**
 * get list monster user
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListMemoryMonsterUserApi = (props) => {
  var uri = `/users/monster-memory`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListMemoryMonsterUser(action) {
  try {
    const response = yield call(getListMemoryMonsterUserApi, action?.payload)
    yield put(getAllMemoryMonsterUserSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getAllMemoryMonsterUserFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list monster user
 */
export default function* watchGetListMemoryMonsterUser() {
  yield takeLatest(
    GET_ALL_MEMORY_MONSTER_USER_START,
    doGetListMemoryMonsterUser,
  )
}
