import ABI_GENESIS from '~/blockchain/abi/Genesis.json'
import ABI_GENERAL from '~/blockchain/abi/General.json'
import ABI_SHOP from '~/blockchain/abi/Shop.json'
import ABI_FARM from '~/blockchain/abi/Farm.json'
import ABI_MONSTER from '~/blockchain/abi/Monster.json'
import ABI_MARKETPLACE from '~/blockchain/abi/Marketplace.json'
import ABI_TRAINING_ITEM from '~/blockchain/abi/TrainingItem.json'
import ABI_ENHANCE_ITEM from '~/blockchain/abi/EnhanceItem.json'
import ABI_FUSION_ITEM from '~/blockchain/abi/FusionItem.json'
import ABI_REGENERATION_ITEM from '~/blockchain/abi/RegenerationItem.json'
import ABI_DEPOSIT_HASHCHIP from '~/blockchain/abi/DepositHashChip.json'
import ABI_CLAIM_HASHCHIP from '~/blockchain/abi/ClaimHashChip.json'
import ABI_HASHCHIP_POLYGON from '~/blockchain/abi/HashChipPolygon.json'

import provider from '~/blockchain/provider/index.js'
import providerPolygon from '~/blockchain/provider/polygon.js'

import configs from '~/configs'
import { ethers } from 'ethers'
import { getSpeciesValue } from '~/helpers/Species'

export async function resultOpenGenesis(txHash) {
  let data = await provider.getTransactionReceipt(txHash)
  let log = data.logs[1]
  var res = ifaceContractGenesis.parseLog(log)
  if (res.name === 'OpenBoxs') {
    return {
      name: 'GENESIS HASH',
      token_id: Number(res.args.tokenId),
      group: Number(res.args.groupId),
      type_id: Number(res.args._type),
      type: getSpeciesValue(Number(res.args.groupId), Number(res.args._type))
        .name,
    }
  }
  return undefined
}

export async function resultOpenGeneral(txHash) {
  let data = await provider.getTransactionReceipt(txHash)
  let log = data.logs[1]
  var res = ifaceContractGenesis.parseLog(log)
  if (res.name === 'OpenBoxs') {
    return {
      name: 'GENERAL HASH',
      token_id: Number(res.args.tokenId),
      group: Number(res.args.groupId),
      type_id: Number(res.args._type),
      type: getSpeciesValue(Number(res.args.groupId), Number(res.args._type))
        .name,
    }
  }
  return undefined
}

// Contract Monster
export const ifaceContractMonster = new ethers.utils.Interface(ABI_MONSTER)
export const ContractMonster = new ethers.Contract(
  configs.ADDRESS_MONSTER,
  ABI_MONSTER,
  provider,
)

// Contract Genesis
export const ifaceContractGeneral = new ethers.utils.Interface(ABI_GENERAL)
export const ContractGeneral = new ethers.Contract(
  configs.ADDRESS_GENERAL,
  ABI_GENERAL,
  provider,
)

// Contract General
export const ifaceContractGenesis = new ethers.utils.Interface(ABI_GENESIS)
export const ContractGenesis = new ethers.Contract(
  configs.ADDRESS_GENESIS,
  ABI_GENESIS,
  provider,
)

// Contract Hash Chip
export const ifaceContractHashChip = new ethers.utils.Interface(
  ABI_HASHCHIP_POLYGON,
)
export const ContractHashChip = new ethers.Contract(
  configs.ADDRESS_HASHCHIP_OAS,
  ABI_HASHCHIP_POLYGON,
  provider,
)

// Contract Shop
export const ifaceContractShop = new ethers.utils.Interface(ABI_SHOP)
export const ContractShop = new ethers.Contract(
  configs.ADDRESS_SHOP,
  ABI_SHOP,
  provider,
)

// Contract Farm
export const ifaceContractFarm = new ethers.utils.Interface(ABI_FARM)
export const ContractFarm = new ethers.Contract(
  configs.ADDRESS_FARM,
  ABI_FARM,
  provider,
)

// Contract Marketplace
export const ifaceContractMarketplace = new ethers.utils.Interface(
  ABI_MARKETPLACE,
)
export const ContractMarketplace = new ethers.Contract(
  configs.ADDRESS_MARKETPLACE,
  ABI_MARKETPLACE,
  provider,
)

// Contract Training Item
export const ifaceContractTrainingItem = new ethers.utils.Interface(
  ABI_TRAINING_ITEM,
)
export const ContractTrainingItem = new ethers.Contract(
  configs.ADDRESS_TRAINING_ITEM,
  ABI_TRAINING_ITEM,
  provider,
)

// Contract Enhance Item
export const ifaceContractEnhanceItem = new ethers.utils.Interface(
  ABI_ENHANCE_ITEM,
)
export const ContractEnhanceItem = new ethers.Contract(
  configs.ADDRESS_ENHANCE_ITEM,
  ABI_ENHANCE_ITEM,
  provider,
)

// Contract Regeneration Item
export const ifaceContractRegenerationItem = new ethers.utils.Interface(
  ABI_REGENERATION_ITEM,
)
export const ContractRegenerationItem = new ethers.Contract(
  configs.ADDRESS_REGENERATION_ITEM,
  ABI_REGENERATION_ITEM,
  provider,
)

// Contract Fusion Item
export const ifaceContractFusionItem = new ethers.utils.Interface(
  ABI_FUSION_ITEM,
)

export const ContractFusionItem = new ethers.Contract(
  configs.ADDRESS_FUSION_ITEM,
  ABI_FUSION_ITEM,
  provider,
)

// Contract Deposit
export const ifaceContractDeposit = new ethers.utils.Interface(
  ABI_DEPOSIT_HASHCHIP,
)
export const ContractDeposit = new ethers.Contract(
  configs.ADDRESS_DEPOSIT_POLYGON,
  ABI_DEPOSIT_HASHCHIP,
  providerPolygon,
)

// Contract claim hashchip
export const ifaceContractClaimHashChip = new ethers.utils.Interface(
  ABI_CLAIM_HASHCHIP,
)
export const ContractClaimHashChip = new ethers.Contract(
  configs.ADDRESS_FUSION_ITEM,
  ABI_FUSION_ITEM,
  provider,
)

// Contract claim hashchip
export const ifaceContractHashChipPolygon = new ethers.utils.Interface(
  ABI_HASHCHIP_POLYGON,
)
export const ContractHashChipPolygon = new ethers.Contract(
  configs.ADDRESS_HASHCHIP_POLYGON,
  ABI_HASHCHIP_POLYGON,
  providerPolygon,
)

export function getTypeItem(type) {
  switch (type) {
    case 'Training_Item':
      return {
        contract: ContractTrainingItem,
        iface: ifaceContractTrainingItem,
        address: configs.ADDRESS_TRAINING_ITEM,
      }
    case 'Enhance_Item':
      return {
        contract: ContractEnhanceItem,
        iface: ifaceContractEnhanceItem,
        address: configs.ADDRESS_ENHANCE_ITEM,
      }
    case 'Fusion_Item':
      return {
        contract: ContractFusionItem,
        iface: ifaceContractFusionItem,
        address: configs.ADDRESS_FUSION_ITEM,
      }
    case 'Regeneration_Hash':
    case 'Regeneration_Item':
      return {
        contract: ContractRegenerationItem,
        iface: ifaceContractRegenerationItem,
        address: configs.ADDRESS_REGENERATION_ITEM,
      }
    default:
      return undefined
  }
}

export function getContractHash(address) {
  switch (address?.toLowerCase()) {
    case configs.ADDRESS_GENERAL.toLowerCase():
      return {
        contract: ContractGeneral,
        iface: ifaceContractGeneral,
        address: configs.ADDRESS_GENERAL,
      }
    case configs.ADDRESS_GENESIS.toLowerCase():
      return {
        contract: ContractGenesis,
        iface: ifaceContractGenesis,
        address: configs.ADDRESS_GENESIS,
      }

    case configs.ADDRESS_HASHCHIP_OAS.toLowerCase():
      return {
        contract: ContractHashChip,
        iface: ifaceContractHashChip,
        address: configs.ADDRESS_HASHCHIP_OAS,
      }
    default:
      return {
        contract: ContractRegenerationItem,
        iface: ifaceContractRegenerationItem,
        address: configs.ADDRESS_REGENERATION_ITEM,
      }
  }
}

export async function getOrderIdFromTxHash(txHash) {
  const receipt = await provider.getTransactionReceipt(txHash)
  let abi = [
    'event OrderCreated(bytes32 orderId, uint256 indexed tokenId,address indexed seller,address nftAddress,uint256 priceInWei,uint256 amount)',
  ]
  let iface = new ethers.utils.Interface(abi)
  let res = iface.parseLog(receipt.logs[1])
  if (res.name === 'OrderCreated') {
    return String(res.args.orderId)
  }
  return undefined
}
