import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Modal, Form, InputNumber } from 'antd'
import { formatNumber } from '~/helpers/common'
import { ContractShop, ContractTrainingItem } from '~/blockchain/contract'
import { cancelButtonStyle, okButtonStyle } from '~/common/constants'
export default function SellToShopModal(props) {
  // eslint-disable-next-line
  const {
    name,
    action,
    t,
    tokenID,
    loadingModal,
    tema,
    balance,
    quanlityTotal,
  } = props
  const [price, setPrice] = useState()
  const [quantity, setQuantity] = useState()
  const [receive, setReceive] = useState(0)

  const [form] = Form.useForm()
  const priceSell = useRef(null)
  const quantitySell = useRef(null)

  const _calulate = (price, maxSupply, currentSupply) => {
    if (currentSupply <= maxSupply * 0.2) {
      return price
    } else if (currentSupply > maxSupply * 0.8) {
      return price * 2
    } else {
      const result =
        price * (1 + (currentSupply - 0.2 * maxSupply) / (0.6 * maxSupply))
      return result
    }
  }

  const calulateTotal = (price, maxSupply, currentSupply, amount) => {
    let total = 0
    for (let i = 0; i < amount; i++) {
      total += Math.floor(_calulate(price, maxSupply, currentSupply + i))
    }
    return total
  }

  const onChangeQuantity = async (value) => {
    if (value.target.value && value.target.value >= 0) {
      const listItemSale = await ContractShop.getListItemSale()
      const detai = listItemSale.find(
        (item) => Number(item?.itemId) === tokenID,
      )

      const detailItemTemp = await ContractTrainingItem.itemDetail(
        Number(detai.itemId) - 3,
      )

      const initPriceTemp = Number(detai.initPrice)
      const currentSupplyC = Number(detailItemTemp.totalAmount)
      const maxSupplyC = Number(detailItemTemp.amountLimit)

      const totalPrice = Math.floor(
        calulateTotal(
          initPriceTemp,
          maxSupplyC,
          currentSupplyC,
          Number(value.target.value),
        ),
      )
      const receive = totalPrice * Number(0.2)

      setQuantity(value.target.value)
      setReceive(Math.floor(receive))
      setPrice(Math.floor(receive))
    }
    if (value.target.value === null) {
      setReceive(0)
      setPrice(0)
    }
  }

  const handelSell = async () => {
    if (loadingModal) return
    if (receive !== 0) {
      form.submit()
    }
  }

  const handleCancel = () => {
    action?.handleCancel()
  }

  useEffect(() => {
    if (action?.isModalOpen) {
      setQuantity(0)
      setReceive(0)
      setPrice(0)
      form.resetFields()
    }
  }, [action?.isModalOpen])
  return (
    <>
      <Form
        form={form}
        preserve={false}
        onFinish={() => action?.handleOk(tokenID, price, quantity)}
        validateTrigger={['onBlur', 'onChange']}
      >
        <Modal
          key={tokenID}
          centered
          title={
            <h3 className="flex justify-center text-xl font-bold">
              {t('modal.sell_shop.title')}
            </h3>
          }
          open={action?.isModalOpen}
          onCancel={handleCancel}
          footer={[
            <div key="buttons" className="flex justify-center">
              <Button
                key="cancel"
                onClick={handleCancel}
                style={cancelButtonStyle}
              >
                {t('modal.sell_shop.cancel')}
              </Button>
              <Button
                key="ok"
                type="primary"
                onClick={handelSell}
                style={okButtonStyle}
                loading={loadingModal}
              >
                {t('modal.sell_shop.ok')}
              </Button>
            </div>,
          ]}
        >
          <div className="pb-5">
            {t('modal.sell_shop.description', {
              name: `${name} ${tokenID ? `(#${tokenID})` : ''}`,
            })}
          </div>
          <div className="flex justify-between">
            <div>{t('modal.sell_shop.quantity')} </div>
            <div>
              {t('modal.sell_shop.max', {
                quanlityTotal: quanlityTotal,
              })}
            </div>
          </div>

          <div className="py-2">
            <Form.Item
              name="quantity"
              rules={[
                {
                  required: true,
                  message: t('common.schema.required'),
                },
                () => ({
                  validator(rule, value) {
                    const integerPattern = /^-?\d+$/
                    const numberPattern = /^-?\d+(\.\d+)?$/

                    if (value && value > quanlityTotal) {
                      return Promise.reject(t('common.schema.totalQuantity'))
                    }
                    if (value === 0) {
                      return Promise.reject(t('common.schema.quantityNumeric'))
                    }
                    if (value && value < 1) {
                      return Promise.reject(t('common.schema.quantityNumeric'))
                    }
                    if (value && !integerPattern.test(value)) {
                      return Promise.reject(t('common.schema.mustBeNumeric'))
                    }
                    if (value && !numberPattern.test(value)) {
                      return Promise.reject(t('common.schema.mustBeNumeric'))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input
                maxLength={7}
                autoComplete="off"
                className=" input-numberSell"
                onChange={onChangeQuantity}
                placeholder={t('modal.sell_shop.placeholder_quantity')}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Form.Item>
          </div>
          {/* <div className="flex justify-between pb-4">
            <div>{t('modal.sell_shop.total_price')}</div>
            <div>{formatNumber(totalPrice)} BIT</div>
          </div>
          <div className="flex justify-between pb-4">
            <div>{t('modal.sell_shop.fee')}</div>
            <div>5%</div>
          </div> */}
          <div className="flex justify-between font-bold">
            <div>{t('modal.sell_shop.receive')}</div>
            <div>{formatNumber(receive)} BIT</div>
          </div>
        </Modal>
      </Form>
    </>
  )
}
