import versionInfo from '~/assets/data/build_info.json'
import BodyCommon from '~/components/shared-components/BodyCommon'

export const VersionComponent = () => {
  // Convert buildDate to a Date object
  const buildDate = new Date(versionInfo.buildDate)

  // Format the date and time
  const formattedDate = buildDate.toLocaleDateString()
  const formattedTime = buildDate.toLocaleTimeString()

  return (
    <div className="text-[25px] ml-[5%] mt-[100px] flex flex-col">
      <p>Version: {versionInfo.version}</p>
      <p>Build Date: {formattedDate}</p>
      <p>Build Time: {formattedTime}</p>
    </div>
  )
}

export default VersionComponent
