import { Button, Card, Col, Row } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'

import { useTranslation } from '~/common/hooks/useTranslation'
import BodyCommon from '~/components/shared-components/BodyCommon'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import {
  convertAddressToProperFormat,
  convertOasToUSD,
  formatAddress,
  formatNumber,
  formatTransaction,
} from '~/helpers/common'
import TimeHelper from '~/helpers/time-helper'
import { InfoHash } from './components/InfoHash'
import configs from '~/configs'
import { useMonster } from '~/views/redux/hooks/useMonster'
import { useEffect, useState } from 'react'
import { getBgHash } from '~/helpers/get-color-parameter'
import { getSpeciesValue } from '~/helpers/Species'
import LoadingHashDetail from '../components/LoadingHashDetail'
import { useCommon } from '~/views/redux/hooks/useCommon'
import {
  checkTransactionConfirmation,
  fromWei,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import { useSDK } from '@metamask/sdk-react'
import { useAccount } from '~/common/hooks/useAccount'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import {
  getContractHash,
  getOrderIdFromTxHash,
  ifaceContractMarketplace,
} from '~/blockchain/contract'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import CheckoutModal from '~/components/Modals/Checkout'
import CancelSell from '~/components/Modals/CancelSell'
import SellModal from '~/components/Modals/SellModal'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import TransactionCompletedSell from '~/components/Modals/TransactionCompletedSell'
import SelectWallet from '~/components/Modals/SelectWallet'
import { getNameItem, getNameItemByContract } from '~/helpers/ItemName'
import { ROUTE } from '~/views/routes/config'
import SellToMKPModal from '~/components/Modals/SellToMKPModal'
import getImageHash from '~/assets/data/ImageHash.json'
export const HashNFTDetail = () => {
  const { sdk } = useSDK()
  // eslint-disable-next-line
  const [txHash, setTxHash] = useState()
  const { user } = useAccount()
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalRegenSell, setIsModalRegenSell] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [isModalSuccessSellOpen, setIsModalSuccessSellOpen] = useState(false)
  const [isModalSellOpen, setIsModalSellOpen] = useState(false)

  // eslint-disable-next-line
  const { t } = useTranslation()
  const { page, pageSize, setPage, setPageSize } = useQueryState()
  const location = useLocation()
  const history = useHistory()
  const [dataHistories, setDataHistories] = useState([])
  const [totalData, setTotalData] = useState(0)
  const {
    actions,
    data: { monsterDetail, isLoading },
  } = useMonster()
  const {
    actions: actionsCommon,
    data: { allTEMAs },
  } = useCommon()
  const columns = [
    {
      title: t('monsterDetail.trading.event'),
      dataIndex: 'event',
      key: 'event',
      align: 'center',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('monsterDetail.trading.from'),
      dataIndex: 'from',
      key: 'from',
      align: 'center',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('monsterDetail.trading.to'),
      dataIndex: 'to',
      key: 'to',
      align: 'center',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('monsterDetail.trading.price'),
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      render: (data) => {
        return (
          <div>
            <Row gutter={4}>
              <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                {formatNumber(data)}
              </Col>
              <Col
                span={12}
                style={{ display: 'flex', justifyContent: 'start' }}
              >
                OAS
              </Col>
            </Row>
          </div>
        )
      },
    },
    {
      title: t('monsterDetail.trading.amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('monsterDetail.trading.date'),
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      render: (data) => {
        return (
          <div>
            {TimeHelper(Number(data) * 1000, {
              format: 'YYYY-MM-DD HH:mm:ss',
            })}
          </div>
        )
      },
    },
    {
      title: t('profile.tradeHistory.txHash'),
      dataIndex: 'txHash',
      align: 'center',
      key: 'txHash',
      render: (data) => {
        const link = `${configs.NETWORK.scan}tx/${data}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatTransaction(data)}
          </a>
        )
      },
    },
  ]

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  useEffect(() => {
    if (user && user !== null) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  const handleShare = () => {
    setIsModalSuccessSellOpen(false)
    handleCancelSuccessSell()
  }

  const handleCancelSuccessSell = () => {
    setIsModalSuccessSellOpen(false)
  }

  const showModalSell = () => {
    setIsModalSellOpen(true)
  }

  const showModalSuccessSell = () => {
    setIsModalSuccessSellOpen(true)
  }

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const handleCancelModalSell = () => {
    setIsModalSellOpen(false)
  }

  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelModalSellRegen = () => {
    setIsModalRegenSell(false)
    setLoadingModal(false)
  }
  const showModalSellRegen = () => {
    setIsModalRegenSell(true)
  }

  const handleSell = async (tokenID, price, quantity = 1) => {
    try {
      setLoadingModal(true)
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        const {
          contract,
          iface,
          address: addressContract,
        } = getContractHash(monsterDetail?.contractAddress)

        let checkAllow
        if (monsterDetail?.type === 'Regeneration Hash') {
          checkAllow = await contract.isApprovedForAll(
            user.address_wallet,
            configs.ADDRESS_MARKETPLACE,
          )
        } else {
          let allowanceHash = await contract.getApproved(tokenID)
          checkAllow =
            allowanceHash.toLowerCase() ===
            configs.ADDRESS_MARKETPLACE.toLowerCase()
        }
        if (checkAllow) {
          sell(tokenID, price, quantity, addressContract)
        } else {
          let data = {
            from: providerOAS.accounts[0],
            data:
              monsterDetail?.type_name === 'Regeneration Hash'
                ? iface.encodeFunctionData('setApprovalForAll', [
                    configs.ADDRESS_MARKETPLACE,
                    true,
                  ])
                : iface.encodeFunctionData('approve', [
                    configs.ADDRESS_MARKETPLACE,
                    tokenID,
                  ]),
            to: addressContract,
            nonce: nonce,
            gasLimit: 21000,
          }

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          })

          const transaction = await ethersWeb3Provider.sendTransaction(
            String(signedTransaction),
          )
          checkTransactionConfirmation(transaction?.hash).then((r) => {
            if (r === 'confirmed') {
              // eslint-disable-next-line
              console.log('Approve:', r, transaction?.hash)
              sell(tokenID, price, quantity, addressContract)
            }
          })
        }
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        const {
          contract,
          iface,
          address: addressContract,
        } = getContractHash(location.pathname.slice(1).split('/')[2])
        let checkAllow

        if (monsterDetail?.type_name === 'Regeneration Hash') {
          checkAllow = await contract.isApprovedForAll(
            user.address_wallet,
            configs.ADDRESS_MARKETPLACE,
          )
        } else {
          let allowanceHash = await contract.getApproved(tokenID)
          checkAllow =
            allowanceHash.toLowerCase() ===
            configs.ADDRESS_MARKETPLACE.toLowerCase()
        }

        if (checkAllow) {
          sell(addressContract, tokenID, price, quantity)
        } else {
          let nonce = await sdk.getProvider().request({
            method: 'eth_getTransactionCount',
            params: [user.address_wallet, 'latest'],
          })
          sdk
            .getProvider()
            .request({
              method: 'eth_sendTransaction',
              params: [
                {
                  from: user.address_wallet,
                  data:
                    monsterDetail?.type_name === 'Regeneration Hash'
                      ? iface.encodeFunctionData('setApprovalForAll', [
                          configs.ADDRESS_MARKETPLACE,
                          true,
                        ])
                      : iface.encodeFunctionData('approve', [
                          configs.ADDRESS_MARKETPLACE,
                          tokenID,
                        ]),
                  to: addressContract,
                  nonce,
                },
              ],
            })
            .then((txHash) => {
              checkTransactionConfirmation(txHash).then((r) => {
                if (r === 'confirmed') {
                  // eslint-disable-next-line
                  console.log('Approve:', r, txHash)
                  sell(addressContract, tokenID, price, quantity)
                }
              })
            })
            .catch((error) => {
              setLoadingModal(false)
              // eslint-disable-next-line
              console.error(error)
            })
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
      setLoadingModal(false)
    }
  }

  const sell = async (addressContract, tokenID, price, quantity) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'createMarketItemSale',
            [addressContract, tokenID, toWei(price), quantity],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then(async (r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            setTxHash(transaction?.hash)
            const orderID = await getOrderIdFromTxHash(transaction?.hash)
            history.push(
              `${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${
                location.pathname.slice(1).split('/')[1]
              }/${location.pathname.slice(1).split('/')[2]}/${orderID}`,
            )
            actions?.getMonsterHashDetail(
              {
                tokenId: location.pathname.slice(1).split('/')[1],
                contract: location.pathname.slice(1).split('/')[2],
                orderId: orderID,
              },
              () => {},
              () => {
                history.push('/pageNotFound')
              },
            )
            setLoadingModal(false)
            handleCancelModalSell()
            showModalSuccessSell()
            handleCancelModalSellRegen()
          }
        })
      } else {
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'createMarketItemSale',
                  [addressContract, tokenID, toWei(price), quantity],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then(async (r) => {
              if (r === 'confirmed') {
                const orderID = await getOrderIdFromTxHash(txHash)
                history.push(
                  `${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${
                    location.pathname.slice(1).split('/')[1]
                  }/${location.pathname.slice(1).split('/')[2]}/${orderID}`,
                )
                actions?.getMonsterHashDetail(
                  {
                    tokenId: location.pathname.slice(1).split('/')[1],
                    contract: location.pathname.slice(1).split('/')[2],
                    orderId: orderID,
                  },
                  () => {},
                  () => {
                    history.push('/pageNotFound')
                  },
                )
                // eslint-disable-next-line
                setTxHash(txHash)
                setLoadingModal(false)
                handleCancelModalSell()
                showModalSuccessSell()
                handleCancelModalSellRegen()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      setLoadingModal(false)
    }
  }

  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            monsterDetail?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(monsterDetail?.price)),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            history.push(
              `${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${
                location.pathname.slice(1).split('/')[1]
              }/${location.pathname.slice(1).split('/')[2]}`,
            )
            actions?.getMonsterHashDetail(
              {
                tokenId: location.pathname.slice(1).split('/')[1],
                contract: location.pathname.slice(1).split('/')[2],
              },
              () => {},
              () => {
                history.push('/pageNotFound')
              },
            )
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  monsterDetail?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(monsterDetail?.price)),
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                history.push(
                  `${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${
                    location.pathname.slice(1).split('/')[1]
                  }/${location.pathname.slice(1).split('/')[2]}`,
                )
                actions?.getMonsterHashDetail(
                  {
                    tokenId: location.pathname.slice(1).split('/')[1],
                    contract: location.pathname.slice(1).split('/')[2],
                  },
                  () => {},
                  () => {
                    history.push('/pageNotFound')
                  },
                )
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [monsterDetail?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            history.push(
              `${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${
                location.pathname.slice(1).split('/')[1]
              }/${location.pathname.slice(1).split('/')[2]}/${undefined}/${
                user?.address_wallet
              }`,
            )
            actions?.getMonsterHashDetail(
              {
                tokenId: location.pathname.slice(1).split('/')[1],
                contract: location.pathname.slice(1).split('/')[2],
                owner: user?.address_wallet,
              },
              () => {},
              () => {
                history.push('/pageNotFound')
              },
            )
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [monsterDetail?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                history.push(
                  `${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${
                    location.pathname.slice(1).split('/')[1]
                  }/${location.pathname.slice(1).split('/')[2]}/${undefined}/${
                    user?.address_wallet
                  }`,
                )
                actions?.getMonsterHashDetail(
                  {
                    tokenId: location.pathname.slice(1).split('/')[1],
                    contract: location.pathname.slice(1).split('/')[2],
                    owner: user?.address_wallet,
                  },
                  () => {},
                  () => {
                    history.push('/pageNotFound')
                  },
                )
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  useEffect(() => {
    actionsCommon.getTema()
    if (monsterDetail.tokenId) {
      actionsCommon.getHistoriesToken(
        {
          addressContract:
            monsterDetail?.contractAddress ||
            location.pathname.slice(1).split('/')[2],
          tokenId: monsterDetail?.tokenId,
          page: page,
        },
        async (res) => {
          let data = await Promise.all(
            res.data.map((temp, index) => {
              return {
                key: index,
                event:
                  temp?.type === 'OrderCreated'
                    ? 'For Sale'
                    : temp?.type === 'OrderSuccessful'
                    ? 'Buy'
                    : undefined,
                time: temp?.timestamp,
                from: temp?.from,
                to: temp?.to,
                price: fromWei(temp?.priceInWei),
                amount: temp.amount,
                txHash: temp?.transactionHash,
                created_at: temp?.timestamp,
              }
            }),
          )
          setTotalData(res?.total)
          setDataHistories(data)
        },
      )
    }
  }, [txHash, monsterDetail])

  useEffect(() => {
    actions?.getMonsterHashDetail(
      {
        tokenId: location.pathname.slice(1).split('/')[1],
        contract: location.pathname.slice(1).split('/')[2],
        orderId: location.pathname.slice(1).split('/')[3],
        owner: location?.pathname?.slice(1).split('/')[4]?.toLowerCase(),
        item_type: location?.pathname?.slice(1).split('/')[5],
      },
      () => {},
      () => {
        history.push('/pageNotFound')
      },
    )
  }, [])

  const ButtonHash = () => {
    if (monsterDetail?.orderId) {
      if (
        monsterDetail?.seller?.toLowerCase() ===
          user?.address_wallet?.toLowerCase() &&
        configs.ADDRESS_MARKETPLACE.toLowerCase()
      ) {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCancelSell()
            }}
          >
            Cancel
          </Button>
        )
      } else {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCheckout()
            }}
          >
            Buy Now
          </Button>
        )
      }
    } else {
      if (
        user?.address_wallet?.toLowerCase() ===
          monsterDetail?.owner_address?.toLowerCase() ||
        user?.address_wallet?.toLowerCase() ===
          monsterDetail?.owner?.toLowerCase()
      ) {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              if (monsterDetail?.type_name === 'Regeneration Hash') {
                showModalSellRegen()
              } else {
                showModalSell()
              }
            }}
          >
            Sell
          </Button>
        )
      } else {
        return <Button className="blockchain__button">Not Sell</Button>
      }
    }
  }
  function getItemGraphic(itemId) {
    // Kiểm tra điều kiện đặc biệt trước khi xử lý
    if (itemId === 'UPA 1912') {
      // eslint-disable-next-line
      itemId = 'UPA_1912'
    }
    if (itemId === 'UPA Gorem') {
      // eslint-disable-next-line
      itemId = 'UPA_Gorem'
    }
    if (itemId === 'UPA AC') {
      // eslint-disable-next-line
      itemId = 'UPA_AC'
    }
    if (itemId === 'UPA ODEN') {
      // eslint-disable-next-line
      itemId = 'UPA_ODEN'
    }
    if (itemId === 'UPA Ossan') {
      // eslint-disable-next-line
      itemId = 'UPA_Ossan'
    }
    if (itemId === 'UPA Villain') {
      // eslint-disable-next-line
      itemId = 'UPA_Villain'
    }
    if (itemId === 'Yamato T Shirt') {
      // eslint-disable-next-line
      itemId = 'YamatoT_Shirt'
    }
    if (itemId === 'Sound Horse') {
      // eslint-disable-next-line
      itemId = 'Soundhorse'
    }

    // Loại bỏ khoảng trắng
    let formattedItemId = itemId?.replace(/\s+/g, '')

    for (let i = 0; i < getImageHash.length; i++) {
      let formattedImageHashItemId = getImageHash[i].itemId.replace(/\s+/g, '')

      if (formattedImageHashItemId === formattedItemId) {
        return getImageHash[i].itemGraphic
      }
    }
    return null
  }

  return (
    <BodyCommon>
      {isLoading && <LoadingHashDetail />}
      {!isLoading && (
        <div className="relative container__common mx-auto z-10 top-[84px]">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-4 pt-5 sm:pt-12 w-full">
            <div className="flex flex-col mb-3 sm:mb-0 items-center justify-center">
              <div className="relative card__detail  h-[300px] md:w-[93.2%] w-[93.2%] md:h-[656px] flex items-center justify-center">
                <div className="relative flex items-center justify-center h-full w-full">
                  <div className="relative flex flex-col w-[93%] h-[93%] justify-between">
                    <div
                      className="h-full flex flex-col items-center p-[10px] text-[18px] justify-center bg-cover bg-no-repeat"
                      style={{
                        background: getBgHash(monsterDetail?.type_name),

                        clipPath:
                          'polygon(97% 100%, 100% 97%, 100% 3%, 97% 0%, 3% 0%, 0% 3%, 0% 97%, 3% 100%)',
                      }}
                    >
                      <img
                        alt=""
                        className={`relative ${
                          monsterDetail?.type_name === 'Hash Chip NFT'
                            ? 'w-[90%] h-[90%]'
                            : 'w-full f-full'
                        } card__detail_img object-contain ease-in-out duration-300`}
                        // className={`card__monster_img object-contain ease-in-out duration-300 ${
                        //   data?.type_name === 'Hash Chip NFT'
                        //     ? 'w-[125px] h-[125px]'
                        //     : 'w-[160px] h-[160px]'
                        // }`}
                        // src={
                        //   monsterDetail?.type_name === 'Regeneration Hash'
                        //     ? getNameItem(3, monsterDetail?.nft_id)?.image
                        //       ? `${
                        //           process.env.REACT_APP_BASE_URL_IMAGE
                        //         }/public/image/image/items/${
                        //           getNameItem(3, monsterDetail?.nft_id)?.image
                        //         }.png`
                        //       : `${
                        //           process.env.REACT_APP_BASE_URL_IMAGE
                        //         }/public/image/image/hash/${getItemGraphic(
                        //           monsterDetail?.name,
                        //         )}`
                        //     : getSpeciesValue(
                        //         monsterDetail?.group,
                        //         monsterDetail?.species,
                        //       )?.image
                        //     ? `${
                        //         process.env.REACT_APP_BASE_URL_IMAGE
                        //       }/public/image/image/hash/${
                        //         getSpeciesValue(
                        //           monsterDetail?.group,
                        //           monsterDetail?.species,
                        //         )?.image
                        //       }.png`
                        //     : `${
                        //         process.env.REACT_APP_BASE_URL_IMAGE
                        //       }/public/image/image/hash/${getItemGraphic(
                        //         monsterDetail?.name,
                        //       )}`
                        // }
                        src={
                          monsterDetail?.type_name === 'Regeneration Hash'
                            ? getNameItem(3, monsterDetail?.nft_id)?.image
                              ? `${
                                  process.env.REACT_APP_BASE_URL_IMAGE
                                }/public/image/image/items/${
                                  getNameItem(3, monsterDetail?.nft_id)?.image
                                }.png`
                              : `${
                                  process.env.REACT_APP_BASE_URL_IMAGE
                                }/public/image/image/hash/${getItemGraphic(
                                  monsterDetail?.name,
                                )}`
                            : monsterDetail?.type_name === 'Hash Chip NFT'
                            ? `${process.env.PUBLIC_URL}/imgs/common/result.png`
                            : getSpeciesValue(
                                monsterDetail?.group,
                                monsterDetail?.species,
                              )?.image
                            ? `${
                                process.env.REACT_APP_BASE_URL_IMAGE
                              }/public/image/image/hash/${
                                getSpeciesValue(
                                  monsterDetail?.group,
                                  monsterDetail?.species,
                                )?.image
                              }.png`
                            : `${
                                process.env.REACT_APP_BASE_URL_IMAGE
                              }/public/image/image/hash/${getItemGraphic(
                                monsterDetail?.name,
                              )}`
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex flex-col">
                <div className="title__monster_detail">
                  {monsterDetail?.type_name}
                </div>
                <div className="title__monster_detail_name">
                  {monsterDetail?.name}
                </div>
              </div>
              <Card className="mt-3 mb-8 w-full">
                {monsterDetail?.price ? (
                  <div>
                    <Row>
                      <Col span={12} className="flex items-center">
                        {monsterDetail?.price && (
                          <div className="relative z-10 flex items-center">
                            <img
                              className="mr-2"
                              src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                              alt=""
                              width={32}
                              height={32}
                            />
                            <div className="flex items-center">
                              <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                {formatNumber(monsterDetail?.price)}
                              </div>
                              <span className="text-[16px] sm:text-[20px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    monsterDetail?.price,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col span={12} className={`${'text-end'}`}>
                        <ButtonHash />{' '}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="flex items-center">
                        <div className="mr-3">Floor Price</div>
                        <div className="relative z-10 flex items-center">
                          <img
                            className="mr-2"
                            src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                            alt=""
                            width={22}
                            height={22}
                          />
                          <div className="flex items-center">
                            <div className="flex text-[14px] sm:text-[16px] font-[700]">
                              {formatNumber(monsterDetail?.floorPrice)}
                            </div>
                            <span className="text-[10px] sm:text-[14px] flex">
                              <div className="px-2"> ~ </div> $
                              {formatNumber(
                                convertOasToUSD(
                                  monsterDetail?.floorPrice,
                                  allTEMAs?.tema,
                                ),
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <>
                    <Row>Floor Price</Row>
                    <Row>
                      <Col span={12} className="flex items-center">
                        <div className="relative z-10 flex items-center">
                          <img
                            className="mr-2"
                            src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                            alt=""
                            width={32}
                            height={32}
                          />
                          {/* <div className="flex items-center">
                            <div className="flex text-[20px] sm:text-[32px] font-[700]">
                              {formatNumber(monsterDetail?.floorPrice)}
                            </div>
                            <span className="text-[16px] sm:text-[20px] flex">
                              <div className="px-2"> ~ </div> $
                              {formatNumber(
                                convertOasToUSD(
                                  monsterDetail?.floorPrice,
                                  allTEMAs?.tema,
                                ),
                              )}
                            </span>
                          </div> */}
                          {monsterDetail?.floorPrice ? (
                            <div className="flex items-center">
                              <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                {formatNumber(monsterDetail?.floorPrice)}
                              </div>
                              <span className="text-[16px] sm:text-[20px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    monsterDetail?.floorPrice,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="text-[24px]">No Listing</div>
                          )}
                        </div>
                      </Col>
                      <Col span={12} className={`${'text-end'}`}>
                        <ButtonHash />
                      </Col>
                    </Row>
                  </>
                )}
              </Card>

              <InfoHash t={t} monsterDetail={monsterDetail} />
            </div>
          </div>

          <div className="mt-5 mb-20">
            <Datatable
              scroll={{ x: 1300 }}
              breadcrumb={
                <div className="text-[24px] mb-5">
                  {t('monsterDetail.tradingHistory')}
                </div>
              }
              columns={columns}
              dataSource={dataHistories}
              loading={false}
              metadata={{
                perPage: pageSize,
                setPerPage: setPageSize,
                page,
                setPage,
                total: totalData === 0 ? 1 : totalData,
              }}
              showPagination
            />
          </div>
        </div>
      )}
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={monsterDetail}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
        // name={
        //   monsterDetail?.type_name === 'Genesis Hash' ||
        //   monsterDetail?.type_name === 'General Hash'
        //     ? `${monsterDetail?.main_seed} (#${monsterDetail?.tokenId})`
        //     : getNameItemByContract(
        //         monsterDetail?.contractAddress,
        //         monsterDetail?.tokenId,
        //       )?.name
        // }
        name={monsterDetail?.name}
      />
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        // name={
        //   monsterDetail?.type_name === 'Genesis Hash' ||
        //   monsterDetail?.type_name === 'General Hash'
        //     ? `${monsterDetail?.main_seed} (#${monsterDetail?.tokenId})`
        //     : getNameItemByContract(
        //         monsterDetail?.contractAddress,
        //         monsterDetail?.tokenId,
        //       )?.name
        // }
        name={monsterDetail?.name}
        loadingModal={loadingModal}
      />
      <SellModal
        action={{
          isModalOpen: isModalSellOpen,
          handleOk: handleSell,
          handleCancel: handleCancelModalSell,
        }}
        t={t}
        tokenID={monsterDetail?.tokenId ?? monsterDetail?.nft_id}
        loadingModal={loadingModal}
        // name={
        //   monsterDetail?.type_name === 'Genesis Hash' ||
        //   monsterDetail?.type_name === 'General Hash'
        //     ? `${monsterDetail?.main_seed} (#${monsterDetail?.tokenId})`
        //     : getNameItemByContract(
        //         monsterDetail?.contractAddress,
        //         monsterDetail?.nft_id,
        //       )?.name
        // }
        name={monsterDetail?.name}
        // tema={tema}
        balance={balanceOAS}
      />

      <SellToMKPModal
        action={{
          isModalOpen: isModalRegenSell,
          handleOk: handleSell,
          handleCancel: handleCancelModalSellRegen,
        }}
        tokenID={monsterDetail?.tokenId}
        t={t}
        loadingModal={loadingModal}
        // name={
        //   monsterDetail?.type_name === 'Genesis Hash' ||
        //   monsterDetail?.type_name === 'General Hash'
        //     ? `${monsterDetail?.main_seed} (#${monsterDetail?.tokenId})`
        //     : getNameItemByContract(
        //         monsterDetail?.contractAddress,
        //         monsterDetail?.tokenId,
        //       )?.name
        // }
        name={monsterDetail?.name}
        // tema={tema}
        balance={balanceOAS}
        quanlityTotal={monsterDetail?.quantity}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        name={`${monsterDetail?.farm_name} (#${monsterDetail?.farm_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        // name={
        //   monsterDetail?.type_name === 'Genesis Hash' ||
        //   monsterDetail?.type_name === 'General Hash'
        //     ? `${monsterDetail?.main_seed} (#${monsterDetail?.tokenId})`
        //     : getNameItemByContract(
        //         monsterDetail?.contractAddress,
        //         monsterDetail?.tokenId,
        //       )?.name
        // }
        name={monsterDetail?.name}
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        // name={
        //   monsterDetail?.type_name === 'Genesis Hash' ||
        //   monsterDetail?.type_name === 'General Hash'
        //     ? `${monsterDetail?.main_seed} (#${monsterDetail?.tokenId})`
        //     : getNameItemByContract(
        //         monsterDetail?.contractAddress,
        //         monsterDetail?.tokenId,
        //       )?.name
        // }
        name={monsterDetail?.name}
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
      <TransactionCompletedSell
        action={{
          isModalOpen: isModalSuccessSellOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccessSell,
        }}
        t={t}
        // name={
        //   monsterDetail?.type_name === 'Genesis Hash' ||
        //   monsterDetail?.type_name === 'General Hash'
        //     ? `${monsterDetail?.main_seed} (#${monsterDetail?.tokenId})`
        //     : getNameItemByContract(
        //         monsterDetail?.contractAddress,
        //         monsterDetail?.nft_id,
        //       )?.name
        // }
        name={monsterDetail?.name}
        store={'Marketplace'}
        txHash={txHash}
      />
    </BodyCommon>
  )
}

export default HashNFTDetail
