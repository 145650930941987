import { ROUTE } from '~/views/routes/config'
import { ReMonsterTwoBorderSm } from '../../Button/ReMonsterTwoBorderSm'
import { useHistory, Link } from 'react-router-dom'
import { getbgQuality } from '~/helpers/quality'
import { useAccount } from '~/common/hooks/useAccount'
import { convertOasToUSD, formatItemName, formatNumber } from '~/helpers/common'

export const ItemsCardMyAsset = (props) => {
  const { data, button, image, t, tema } = props

  const { user } = useAccount()
  const history = useHistory()

  const itemLink =
    data?.orderId !== undefined
      ? `${ROUTE.ITEM_DETAIL.SUB_PATH}/${data?.item_type}/${
          data?.seller ?? user?.address_wallet
        }/${data?.orderId}/${data?.nft_id}/${data?.type}/${data?.amount}`
      : `${ROUTE.ITEM_DETAIL.SUB_PATH}/${data?.item_type}/${
          data?.seller ?? user?.address_wallet
        }/${data?.orderId}/${data?.nft_id}/${data?.type}/${data?.quantity}`
  const handleLinkClick = (e) => {
    if (e.target.closest('.common__btn')) {
      e.preventDefault()
    }
  }

  return (
    <Link
      to={itemLink}
      onClick={handleLinkClick}
      className=" hover:text-[#FFFFFF]"
    >
      <div
        className="card__monster w-[250px] h-[350px] cursor-pointer"
        // onClick={(e) => {
        //   if (!e.target.closest('.common__btn')) {
        //     if (data?.orderId !== undefined) {
        //       history.push(
        //         `${ROUTE.ITEM_DETAIL.SUB_PATH}/${data?.item_type}/${
        //           data?.seller ?? user?.address_wallet
        //         }/${data?.orderId}`,
        //       )
        //     } else {
        //       history.push(
        //         `${ROUTE.ITEM_DETAIL.SUB_PATH}/${data?.item_type}/${
        //           data?.seller ?? user?.address_wallet
        //         }`,
        //       )
        //     }
        //   }
        // }}
      >
        <div className="relative flex items-center justify-center h-full">
          <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
            <div
              className="h-full flex flex-col p-[10px] text-[18px]"
              style={{
                background: getbgQuality(data?.quality),
              }}
            >
              <div className="flex items-center">
                <img
                  className="mr-2 w-[32px] h-[32px] object-contain	"
                  src={`../svg/qualityTag/${data?.quality}.svg`}
                  alt=""
                />
                <div className="text-[14px]">
                  {data?.item_type === 'REGENERATION_HASH_OOO_R'
                    ? `Regeneration Hash [${formatItemName(data?.name)}]`
                    : formatItemName(data?.name)}
                </div>
              </div>

              <div className="h-full flex items-center justify-center">
                <img
                  alt=""
                  className="card__monster_img w-[153px] h-[153px] object-contain	ease-in-out duration-300"
                  src={image ?? '../imgs/hash/golem.png'}
                />
              </div>
              <div className="text-[12px] flex w-full justify-between">
                <div>{t('quantity')}</div>
                <div>{data?.quantity ?? data?.amount}</div>
              </div>
            </div>

            <div className="h-[24%] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
              {data?.price && (
                <>
                  <div className="flex items-center justify-center">
                    <img
                      className="w-[16px] h-[16px] object-contain	"
                      src="../imgs/coins/oasis.svg"
                      alt=""
                    />
                    <div className="relative whitespace-nowrap flex ml-2">
                      {formatNumber(data?.price)}
                    </div>
                  </div>

                  <div className="text-[12px] mb-1">
                    ${formatNumber(convertOasToUSD(data?.price, tema?.tema))}
                  </div>
                </>
              )}
              <ReMonsterTwoBorderSm
                quality={data?.quality}
                title={button?.title}
                functionButton={button?.functionButton}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
