import { ROUTE } from '~/views/routes/config'
import { ReMonsterTwoBorderSm } from '../../Button/ReMonsterTwoBorderSm'
import { useHistory, Link } from 'react-router-dom'
import { convertOasToUSD, formatNumber } from '~/helpers/common'
import { getBgRank } from '~/helpers/getValutByRank'
import {
  convertImageFamrOfMonster,
  getValueByFarmTerrain,
} from '~/helpers/getValueByFarmTerrain'
import { Image } from 'antd'
import { convertImageMonster } from '~/common/constants'

export const MonstersMemoryCard = (props) => {
  const { data, button, tema, t, monsterMemory } = props
  const history = useHistory()
  const handleLinkClick = (e) => {
    if (e.target.closest('.common__btn')) {
      e.preventDefault()
    }
  }

  return (
    <Link
      to={`${ROUTE.MONSTER_MEMORY_DETAIL.SUB_PATH}/${data?.monster_nft_id}`}
      onClick={handleLinkClick}
      className=" hover:text-[#FFFFFF]"
    >
      <div
        className="card__monster w-[250px] h-[350px] cursor-pointer"
        // onClick={(e) => {
        //   if (!e.target.closest('.common__btn')) {
        //     history.push(
        //       `${ROUTE.MONSTER_DETAIL.SUB_PATH}/${data?.monster_nft_id}`,
        //     )
        //   }
        // }}
      >
        <div className="relative flex items-center justify-center h-full">
          <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
            <div
              className={`h-full flex flex-col p-[10px] text-[18px] bg-cover	bg-no-repeat`}
              // style={{
              //   backgroundImage: `url(${
              //     process.env.PUBLIC_URL
              //   }/imgs/farm/monsterFarm/${getValueByFarmTerrain(
              //     data?.player_farm_list?.farm_terrain,
              //   )}.png)`,
              // }}
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL
                }/imgs/farm/monsterFarm/${convertImageFamrOfMonster(
                  data?.monster_script_id,
                )}.png)`,
              }}
            >
              <div className="flex items-center">
                <img
                  className="mr-2 w-[32px] h-[32px] object-contain	"
                  src={`../svg/rankTag/${
                    data?.monster_rank === '' ? '0' : data?.monster_rank
                  }.svg`}
                  alt=""
                />
                <div className="title__card">{data?.monster_name}</div>
              </div>
              <div className="absolute left-[5%] top-[16%]">
                <div
                  className="font__M__plus font-bold text-[12px] min-w-[48px] h-[24px] flex items-center justify-center "
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: 4,
                  }}
                >
                  {data ? `#${data?.monster_nft_id}` : ''}
                </div>
              </div>
              <div className="h-full flex items-center justify-center">
                <div className="relative">
                  <Image
                    width={160}
                    height={160}
                    fallback="../imgs/common/aries_avatar.gif"
                    alt=""
                    loading="lazy"
                    className="relative card__monster_img w-[160px] h-[160px] object-contain	ease-in-out duration-300 z-50"
                    src={`${process.env.REACT_APP_BASE_URL_IMAGE}/public/image/gif/${data?.monster_script_id}.gif`}
                    // src={`${
                    //   process.env.REACT_APP_BASE_URL_IMAGE
                    // }/public/image/monsters/${convertImageMonster(
                    //   data?.monster_script_id,
                    // )}.png`}
                  />
                  {/* {Math.floor(Math.random() * 10) > 5 && (
                <>
                  <img
                    className="absolute bottom-[-13%] z-40"
                    src={`${process.env.PUBLIC_URL}/imgs/common/plaform.png`}
                    alt=""
                  />
                  <img
                    className="absolute bottom-[-14%] z-30"
                    src={`${process.env.PUBLIC_URL}/imgs/common/platform_shadow.png`}
                    alt=""
                  />
                </>
              )} */}
                  <div className="absolute bottom-[-20px] left-[-20px] ">
                    {(data?.overall).toFixed(0)}
                  </div>
                </div>
              </div>
            </div>

            <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
              {data?.price && (
                <>
                  <div className="flex items-center justify-center">
                    <img
                      className="w-[16px] h-[16px] object-contain	"
                      src="../imgs/coins/oasis.svg"
                      alt=""
                    />
                    <div className="relative whitespace-nowrap flex ml-2">
                      {formatNumber(data?.price)}
                    </div>
                  </div>

                  <div className="text-[12px] mb-1">
                    ${formatNumber(convertOasToUSD(data?.price, tema?.tema))}
                  </div>
                </>
              )}

              <ReMonsterTwoBorderSm
                t={t}
                title={button?.title}
                functionButton={button?.functionButton}
                data={data}
                monsterMemory={monsterMemory}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
