import { Button, Card, Col, Image, Popover, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyCommon from '~/components/shared-components/BodyCommon'
import { ButtonPolygon } from '~/components/shared-components/Button/ButtonPolygon'
import GeneralMonster from './components/GeneralMonster'
import Battle1Monster from './components/Battle1Monster'
import Battle2Monster from './components/Battle2Monster'
import OtherMonster from './components/OtherMonster'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import TimeHelper from '~/helpers/time-helper'
import { useMonster } from '~/views/redux/hooks/useMonster'
import {
  convertAddressToProperFormat,
  convertOasToUSD,
  formatAddress,
  formatNumber,
  formatTransaction,
  getMintType,
} from '~/helpers/common'
import { useCommon } from '~/views/redux/hooks/useCommon'
import LoadingMonsterDetail from './components/LoadingMonsterDetail'
import configs from '~/configs'
import {
  checkTransactionConfirmation,
  fromWei,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import {
  convertImageFamrOfMonster,
  getValueByFarmTerrain,
} from '~/helpers/getValueByFarmTerrain'
import { useSDK } from '@metamask/sdk-react'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import {
  ContractMonster,
  ifaceContractMarketplace,
  ifaceContractMonster,
} from '~/blockchain/contract'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import CheckoutModal from '~/components/Modals/Checkout'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import SelectWallet from '~/components/Modals/SelectWallet'
import TransactionCompletedSell from '~/components/Modals/TransactionCompletedSell'
import SellModal from '~/components/Modals/SellModal'
import { useAccount } from '~/common/hooks/useAccount'
import {
  converImageCollection,
  convertImageMonster,
  extractConvertMonsterSeed,
} from '~/common/constants'
import { ROUTE } from '~/views/routes/config'

export const MonsterDetail = () => {
  const { sdk } = useSDK()
  // eslint-disable-next-line
  const [txHash, setTxHash] = useState()
  const { user } = useAccount()
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [isModalSuccessSellOpen, setIsModalSuccessSellOpen] = useState(false)
  const [isModalSellOpen, setIsModalSellOpen] = useState(false)

  const { t } = useTranslation()
  const {
    actions,
    data: { isLoadingDetail, monsterDetail },
  } = useMonster()

  const location = useLocation()
  const history = useHistory()
  const {
    actions: actionsCommon,
    data: { allTEMAs, isLoadingTrade },
  } = useCommon()
  const [showGeneralMonster, setShowGeneralMonster] = useState(true)
  const [showBattle1Monster, setShowBattle1Monster] = useState(false)
  const [showBattle2Monster, setShowBattle2Monster] = useState(false)
  const [showOtherMonster, setShowOtherMonster] = useState(false)
  const { page, pageSize, setPage, setPageSize } = useQueryState()
  const clearShowMonster = () => {
    setShowGeneralMonster(false)
    setShowBattle1Monster(false)
    setShowBattle2Monster(false)
    setShowOtherMonster(false)
  }
  const [dataHistories, setDataHistories] = useState([])
  const [totalData, setTotalData] = useState(0)
  const columns = [
    {
      title: t('monsterDetail.trading.event'),
      dataIndex: 'event',
      key: 'event',
      align: 'center',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('monsterDetail.trading.from'),
      dataIndex: 'from',
      key: 'from',
      align: 'center',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('monsterDetail.trading.to'),
      dataIndex: 'to',
      align: 'center',
      key: 'to',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('monsterDetail.trading.price'),
      dataIndex: 'price',
      align: 'center',
      key: 'price',
      render: (data) => {
        return (
          <div>
            <Row gutter={4}>
              <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                {formatNumber(data)}
              </Col>
              <Col
                span={12}
                style={{ display: 'flex', justifyContent: 'start' }}
              >
                OAS
              </Col>
            </Row>
          </div>
        )
      },
    },
    {
      title: t('monsterDetail.trading.amount'),
      dataIndex: 'amount',
      align: 'center',
      key: 'amount',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('monsterDetail.trading.date'),
      dataIndex: 'created_at',
      align: 'center',
      key: 'date',
      render: (data) => {
        return (
          <div>
            {TimeHelper(Number(data) * 1000, {
              format: 'YYYY-MM-DD HH:mm:ss',
            })}
          </div>
        )
      },
    },
    {
      title: t('monsterDetail.trading.txHash'),
      dataIndex: 'txHash',
      align: 'center',
      key: 'txHash',
      render: (data) => {
        const link = `${configs.NETWORK.scan}tx/${data}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatTransaction(data)}
          </a>
        )
      },
    },
  ]

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  useEffect(() => {
    if (user && user !== null) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  const handleShare = () => {
    setIsModalSuccessSellOpen(false)
  }

  const handleCancelSuccessSell = () => {
    setIsModalSuccessSellOpen(false)
  }

  const showModalSell = () => {
    setIsModalSellOpen(true)
  }

  const showModalSuccessSell = () => {
    setIsModalSuccessSellOpen(true)
  }

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const handleCancelModalSell = () => {
    setIsModalSellOpen(false)
  }

  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleSell = async (tokenID, price) => {
    try {
      setLoadingModal(true)
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let allowance = await ContractMonster.getApproved(tokenID)
        if (
          allowance.toLowerCase() === configs.ADDRESS_MARKETPLACE.toLowerCase()
        ) {
          sell(tokenID, price)
        } else {
          let data = {
            from: providerOAS.accounts[0],
            data: ifaceContractMonster.encodeFunctionData('approve', [
              configs.ADDRESS_MARKETPLACE,
              tokenID,
            ]),
            to: configs.ADDRESS_MONSTER,
            nonce: nonce,
            gasLimit: 21000,
          }

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          })

          const transaction = await ethersWeb3Provider.sendTransaction(
            String(signedTransaction),
          )
          checkTransactionConfirmation(transaction?.hash).then((r) => {
            if (r === 'confirmed') {
              // eslint-disable-next-line
              console.log('Approve:', r, transaction?.hash)
              sell(tokenID, price)
            }
          })
        }
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        let allowance = await ContractMonster.getApproved(tokenID)
        if (
          allowance.toLowerCase() === configs.ADDRESS_MARKETPLACE.toLowerCase()
        ) {
          sell(tokenID, price)
        } else {
          let nonce = await sdk.getProvider().request({
            method: 'eth_getTransactionCount',
            params: [user.address_wallet, 'latest'],
          })
          sdk
            .getProvider()
            .request({
              method: 'eth_sendTransaction',
              params: [
                {
                  from: user.address_wallet,
                  data: ifaceContractMonster.encodeFunctionData('approve', [
                    configs.ADDRESS_MARKETPLACE,
                    tokenID,
                  ]),
                  to: configs.ADDRESS_MONSTER,
                  nonce,
                },
              ],
            })
            .then((txHash) => {
              checkTransactionConfirmation(txHash).then((r) => {
                if (r === 'confirmed') {
                  // eslint-disable-next-line
                  console.log('Approve:', r, txHash)
                  sell(tokenID, price)
                }
              })
            })
            .catch((error) => {
              setLoadingModal(false)
              // eslint-disable-next-line
              console.error(error)
            })
        }
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const sell = async (tokenID, price) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'createMarketItemSale',
            [configs.ADDRESS_MONSTER, tokenID, toWei(price), 1],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancelModalSell()
            setTxHash(transaction?.hash)
            showModalSuccessSell()
          }
        })
      } else {
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'createMarketItemSale',
                  [configs.ADDRESS_MONSTER, tokenID, toWei(price), 1],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancelModalSell()
                setTxHash(txHash)
                showModalSuccessSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      setLoadingModal(false)
    }
  }

  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            monsterDetail?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(monsterDetail?.price)),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  monsterDetail?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(monsterDetail?.price)),
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [monsterDetail?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }
        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [monsterDetail?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  useEffect(() => {
    if (monsterDetail?.monster_nft_id) {
      actionsCommon.getHistoriesToken(
        {
          addressContract: configs?.ADDRESS_MONSTER,
          tokenId: monsterDetail?.monster_nft_id,
          page: page,
        },
        async (res) => {
          let data = await Promise.all(
            res.data.map((temp, index) => {
              return {
                key: index,
                event:
                  temp?.type === 'OrderCreated'
                    ? 'For Sale'
                    : temp?.type === 'OrderSuccessful'
                    ? 'Buy'
                    : undefined,
                time: temp?.timestamp,
                from: temp?.from,
                to: temp?.to,
                price: fromWei(temp?.priceInWei),
                amount: temp.amount,
                txHash: temp?.transactionHash,
                created_at: temp?.timestamp,
              }
            }),
          )
          setTotalData(res?.total)
          setDataHistories(data)
        },
      )
    }
  }, [txHash, page, monsterDetail])
  const [checkMonsterFree, setCheckMonsterFree] = useState(false)
  const [checkMonsterMemory, setCheckMonsterMemory] = useState(false)

  useEffect(() => {
    if (monsterDetail?.is_free_monster === true) {
      setCheckMonsterFree(true)
    } else if (monsterDetail?.is_free_monster === false) {
      setCheckMonsterFree(false)
    }
  }, [monsterDetail])

  useEffect(() => {
    if (monsterDetail?.is_memory === true) {
      setCheckMonsterMemory(true)
    } else if (monsterDetail?.is_memory === false) {
      setCheckMonsterMemory(false)
    }
  }, [monsterDetail])

  useEffect(() => {
    actionsCommon.getTema()
    if (isNaN(location?.pathname?.slice(9))) {
      history.push('/pageNotFound')
    } else {
      actions?.getDetailMonster(
        location?.pathname?.slice(9),
        () => {},
        () => {
          history.push('/pageNotFound')
        },
      )
    }
  }, [txHash])

  const ButtonMonster = () => {
    if (monsterDetail?.orderId) {
      if (
        monsterDetail?.seller?.toLowerCase() ===
          user?.address_wallet?.toLowerCase() &&
        monsterDetail?.owner?.toLowerCase() ===
          configs.ADDRESS_MARKETPLACE.toLowerCase()
      ) {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCancelSell()
            }}
          >
            Cancel
          </Button>
        )
      } else {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCheckout()
            }}
          >
            Buy Now
          </Button>
        )
      }
    } else {
      if (user?.address_wallet === monsterDetail?.owner) {
        return (
          <>
            {checkMonsterFree ? (
              <Popover content={<>{t('messageCheckFree')}</>}>
                <Button
                  className="btnCheckSell blockchain__button"
                  disabled={checkMonsterFree}
                >
                  Sell
                </Button>
              </Popover>
            ) : checkMonsterMemory ? (
              <Popover content={<>{t('messageCheckMemory')}</>}>
                <Button
                  className="btnCheckSell blockchain__button"
                  disabled={checkMonsterMemory}
                >
                  Memory is not for sale
                </Button>
              </Popover>
            ) : (
              <Button
                className="blockchain__button"
                onClick={() => showModalSell()}
              >
                Sell
              </Button>
            )}
          </>
        )
      } else {
        return checkMonsterMemory ? (
          <Popover content={<>{t('messageCheckMemory')}</>}>
            <Button
              className="btnCheckSell blockchain__button"
              disabled={checkMonsterMemory}
            >
              Memory is not for sale
            </Button>
          </Popover>
        ) : (
          <Button className="blockchain__button">Not Sell</Button>
        )
      }
    }
  }
  return (
    <BodyCommon>
      {isLoadingDetail && <LoadingMonsterDetail />}

      {!isLoadingDetail && (
        <>
          <div className="relative container__common mx-auto z-10 top-[84px]">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-4 pt-5 sm:pt-12">
              <div className="flex flex-col mb-3 sm:mb-0 items-center">
                <div className="relative card__detail w-[93.2%] h-[300px] md:w-[93.2%] md:h-[656px] flex items-center justify-center">
                  <div className="relative flex items-center justify-center h-full w-full">
                    <div className="relative flex flex-col w-[93%] h-[93%] justify-between">
                      <div
                        className="h-full flex flex-col items-center p-[10px] text-[18px] justify-center bg-cover bg-no-repeat"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL
                          }/imgs/farm/monsterFarm/${convertImageFamrOfMonster(
                            monsterDetail?.monster_script_id,
                          )}.png)`,
                          clipPath:
                            'polygon(97% 100%, 100% 97%, 100% 3%, 97% 0%, 3% 0%, 0% 3%, 0% 97%, 3% 100%)',
                          position: 'relative', // Add this line
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: 10,
                            left: 0,
                            marginLeft: '40px',
                          }}
                        >
                          <img
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: 'contain',
                            }}
                            src={`../imgs/logoMonster/${converImageCollection(
                              extractConvertMonsterSeed(
                                monsterDetail.monster_script_id,
                              ).mainSeed,
                            )}.png`}
                            alt=""
                          />
                        </div>
                        <div className="relative">
                          <Image
                            className="relative card__detail_img w-[80%] h-[80%] max-h-[350px] object-contain	ease-in-out duration-300 z-50	"
                            // src={`${
                            //   process.env.REACT_APP_BASE_URL_IMAGE
                            // }/public/image/monsters/${convertImageMonster(
                            //   monsterDetail?.monster_script_id,
                            // )}.png`}
                            src={`${process.env.REACT_APP_BASE_URL_IMAGE}/public/image/gif/${monsterDetail?.monster_script_id}.gif`}
                            alt=""
                            fallback={`${process.env.PUBLIC_URL}/imgs/common/aries_avatar.gif`}
                            preview={false}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Card className="mt-3 w-full">
                  <Row>
                    <Col span={12} className="text-[16px]">
                      <div className="mb-3 h-[25px] font__M__plus font-bold">
                        {t('monsterDetail.contract')}
                      </div>
                      <div className="mb-3 h-[25px] font__M__plus font-bold">
                        {t('monsterDetail.cycle')}
                      </div>
                      <div className="mb-3 h-[25px] font__M__plus font-bold">
                        {t('monsterDetail.minter')}
                      </div>
                      <div className="mb-3 h-[25px] font__M__plus font-bold">
                        {t('monsterDetail.MintType')}
                      </div>
                      <div className="font__M__plus font-bold h-[25px]">
                        {t('monsterDetail.free')}
                      </div>
                    </Col>
                    <Col span={12} className="text-end">
                      <div className="text-[#40a9ff] mb-3 h-[25px] font__M__plus font-medium">
                        <a
                          style={{ color: '#40A9FF' }}
                          href={`${configs.NETWORK.scan}address/${
                            monsterDetail?.contractAddress ??
                            configs.ADDRESS_MONSTER
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {convertAddressToProperFormat(
                            formatAddress(
                              monsterDetail?.contractAddress ??
                                configs.ADDRESS_MONSTER,
                            ),
                          )}
                        </a>
                      </div>
                      <div className="mb-3 h-[25px] font__M__plus font-medium">
                        {monsterDetail?.cycle}
                      </div>
                      <div className="mb-3 h-[25px] font__M__plus font-medium">
                        {formatAddress(
                          // monsterDetail?.orderId
                          //   ? monsterDetail?.seller
                          //   : monsterDetail?.owner,
                          monsterDetail?.minter,
                        )}
                      </div>
                      <div className="mb-3 h-[25px] font__M__plus font-medium">
                        {getMintType(Number(monsterDetail?.mint_type))}
                      </div>
                      <div className="font__M__plus font-medium h-[25px]">
                        {monsterDetail?.mint_type === 2 ||
                        monsterDetail?.mint_type === '2'
                          ? t('yes')
                          : t('no')}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div className="col-span-2">
                <div className="flex items-center">
                  <img
                    className="w-[50px] h-[50px] object-contain mr-2"
                    src={`../svg/rankTag/${
                      monsterDetail?.monster_rank === ''
                        ? '0'
                        : monsterDetail?.monster_rank
                    }.svg`}
                    alt=""
                  />
                  <div className="title__monster_detail">
                    {monsterDetail?.monster_name === 'P-1'
                      ? 'P1'
                      : monsterDetail?.monster_name}
                  </div>
                </div>
                <Card className="mt-3">
                  {monsterDetail?.price ? (
                    <div>
                      <Row>
                        <Col span={12} className="flex items-center">
                          {monsterDetail?.price && (
                            <div className="relative z-10 flex items-center">
                              <img
                                className="mr-2"
                                src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                                alt=""
                                width={32}
                                height={32}
                              />
                              <div className="flex items-center">
                                <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                  {formatNumber(monsterDetail?.price)}
                                </div>
                                <span className="text-[16px] sm:text-[20px] flex">
                                  <div className="px-2"> ~ </div> $
                                  {formatNumber(
                                    convertOasToUSD(
                                      monsterDetail?.price,
                                      allTEMAs?.tema,
                                    ),
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col span={12} className={`${'text-end'}`}>
                          <ButtonMonster />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} className="flex items-center">
                          <div className="mr-3">Floor Price</div>
                          <div className="relative z-10 flex items-center">
                            <img
                              className="mr-2"
                              src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                              alt=""
                              width={22}
                              height={22}
                            />
                            <div className="flex items-center">
                              <div className="flex text-[14px] sm:text-[16px] font-[700]">
                                {formatNumber(monsterDetail?.floorPrice)}
                              </div>
                              <span className="text-[10px] sm:text-[14px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    monsterDetail?.floorPrice,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <>
                      <Row>Floor Price</Row>
                      <Row>
                        <Col span={12} className="flex items-center">
                          <div className="relative z-10 flex items-center">
                            <img
                              className="mr-2"
                              src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                              alt=""
                              width={32}
                              height={32}
                            />
                            {monsterDetail?.floorPrice ? (
                              <div className="flex items-center">
                                <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                  {formatNumber(monsterDetail?.floorPrice)}
                                </div>
                                <span className="text-[16px] sm:text-[20px] flex">
                                  <div className="px-2"> ~ </div> $
                                  {formatNumber(
                                    convertOasToUSD(
                                      monsterDetail?.floorPrice,
                                      allTEMAs?.tema,
                                    ),
                                  )}
                                </span>
                              </div>
                            ) : (
                              <div className="text-[24px]">No Listing</div>
                            )}
                          </div>
                        </Col>
                        <Col span={12} className={`${'text-end'}`}>
                          <ButtonMonster />
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
                <Row className="mt-8">
                  <Col className="grid grid-cols-3 md:grid-cols-4 gap-2">
                    <ButtonPolygon
                      active={showGeneralMonster}
                      text={t('monsterDetail.infoButton.general')}
                      onClick={() => {
                        clearShowMonster()
                        setShowGeneralMonster(true)
                      }}
                    />

                    <ButtonPolygon
                      active={showBattle1Monster}
                      text={t('monsterDetail.infoButton.battle', { no: 1 })}
                      onClick={() => {
                        clearShowMonster()
                        setShowBattle1Monster(true)
                      }}
                    />
                    <ButtonPolygon
                      active={showBattle2Monster}
                      text={t('monsterDetail.infoButton.battle', { no: 2 })}
                      onClick={() => {
                        clearShowMonster()
                        setShowBattle2Monster(true)
                      }}
                    />
                    <ButtonPolygon
                      active={showOtherMonster}
                      text={t('monsterDetail.infoButton.other')}
                      onClick={() => {
                        clearShowMonster()
                        setShowOtherMonster(true)
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col span={24} className="">
                    {showGeneralMonster && (
                      <GeneralMonster monsterDetail={monsterDetail} t={t} />
                    )}
                    {showBattle1Monster && (
                      <Battle1Monster monsterDetail={monsterDetail} t={t} />
                    )}
                    {showBattle2Monster && (
                      <Battle2Monster monsterDetail={monsterDetail} t={t} />
                    )}
                    {showOtherMonster && (
                      <OtherMonster monsterDetail={monsterDetail} t={t} />
                    )}
                  </Col>
                </Row>
              </div>
            </div>

            <div className="mt-5 mb-20">
              <Datatable
                scroll={{ x: 1300 }}
                breadcrumb={
                  <div className="text-[24px] mb-5">
                    {t('monsterDetail.tradingHistory')}
                  </div>
                }
                columns={columns}
                dataSource={dataHistories}
                loading={isLoadingTrade}
                metadata={{
                  perPage: pageSize,
                  setPerPage: setPageSize,
                  page,
                  setPage,
                  total: totalData === 0 ? 1 : totalData,
                }}
                showPagination
              />
            </div>
          </div>
        </>
      )}
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={monsterDetail}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
      />
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        name={`Monster(#${monsterDetail?.monster_nft_id})`}
        loadingModal={loadingModal}
        // name={'Monster'}
        // tema={tema}
      />

      <SellModal
        action={{
          isModalOpen: isModalSellOpen,
          handleOk: handleSell,
          handleCancel: handleCancelModalSell,
        }}
        t={t}
        tokenID={monsterDetail?.monster_nft_id}
        loadingModal={loadingModal}
        name={'Monster'}
        balance={balanceOAS}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        name={`Monster(#${monsterDetail?.monster_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        name={`Monster(#${monsterDetail?.monster_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedSell
        action={{
          isModalOpen: isModalSuccessSellOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccessSell,
        }}
        t={t}
        name={`Monster(#${monsterDetail?.monster_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
    </BodyCommon>
  )
}

export default MonsterDetail
