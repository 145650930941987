import { Card } from 'antd'
import { ICON_TREE } from '~/assets/svg/button'
import SeedTreModal from './components/SeedTreModal'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  REACT_APP_ADDRESS_MONSTER,
  converImageCollection,
  converNameChain,
  converNameCollection,
  converNamePublicMainSubSeed,
  convertImageMonsterOther,
  convertNameMonsterFusion,
  extractConvertMonsterSeed,
  getCollectionMonster,
} from '~/common/constants'
import { formatAddress, formatNumberOreral } from '~/helpers/common'
import { getRankbyValue } from '~/helpers/getValutByRank'
import buffData from '~/assets/data/monster_buff_debuff.data.json'
import { calculateIt, handleCalculateOverall } from '~/helpers/monster'
import configs from '~/configs'
import { ROUTE } from '~/views/routes/config'
export const OtherMonster = (props) => {
  const { t, monsterDetail } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModalCheckout = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const key1 = `${
    monsterDetail?.seedTrees[0]?.main_seed?.indexOf('(') === -1
      ? monsterDetail?.seedTrees[0]?.main_seed.replace('-', '')
      : monsterDetail?.seedTrees[0]?.main_seed
          ?.slice(0, monsterDetail?.seedTrees[0]?.main_seed?.indexOf('('))
          .replace('-', '')
  }_${
    monsterDetail?.seedTrees[0]?.sub_seed?.indexOf('(') === -1
      ? monsterDetail?.seedTrees[0]?.sub_seed.replace('-', '')
      : monsterDetail?.seedTrees[0]?.sub_seed
          ?.slice(0, monsterDetail?.seedTrees[0]?.sub_seed?.indexOf('('))
          .replace('-', '')
  }`
  const key2 = `${
    monsterDetail?.seedTrees[1]?.main_seed?.indexOf('(') === -1
      ? monsterDetail?.seedTrees[1]?.main_seed.replace('-', '')
      : monsterDetail?.seedTrees[1]?.main_seed
          ?.slice(0, monsterDetail?.seedTrees[1]?.main_seed?.indexOf('('))
          .replace('-', '')
  }_${
    monsterDetail?.seedTrees[1]?.sub_seed?.indexOf('(') === -1
      ? monsterDetail?.seedTrees[1]?.sub_seed.replace('-', '')
      : monsterDetail?.seedTrees[1]?.sub_seed
          ?.slice(0, monsterDetail?.seedTrees[1]?.sub_seed?.indexOf('('))
          .replace('-', '')
  }`
  const lastBuffData1 = buffData[key1]
  const lastBuffData2 = buffData[key2]

  const isMemoryMain = monsterDetail?.seedTrees[0]?.is_memory
  const isMemorySub = monsterDetail?.seedTrees[1]?.is_memory
  const linkPathMain = isMemoryMain
    ? `${ROUTE.MONSTER_MEMORY_DETAIL.SUB_PATH}/${monsterDetail?.seedTrees[0]?.monster_nft_id}`
    : `${ROUTE.MONSTER_DETAIL.SUB_PATH}/${monsterDetail?.seedTrees[0]?.monster_nft_id}`
  const linkPathSub = isMemorySub
    ? `${ROUTE.MONSTER_MEMORY_DETAIL.SUB_PATH}/${monsterDetail?.seedTrees[1]?.monster_nft_id}`
    : `${ROUTE.MONSTER_DETAIL.SUB_PATH}/${monsterDetail?.seedTrees[1]?.monster_nft_id}`

  return (
    <Card className="w-full">
      <div className="flex flex-col gap-4">
        {(monsterDetail?.mint_type < 10 || monsterDetail?.mint_type === 11) && (
          <>
            {/* Regeneration from */}
            <div className="">
              <div className=" text-[24px] mb-4">
                {t('monsterDetail.other.regenerationFrom')}
              </div>

              <div className="bg-[#020f38] p-[20px] rounded-[8px]">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between">
                    <div className="text-[16px]  font-bold">
                      {t('monsterDetail.other.chain')}
                    </div>
                    <div className="text-[16px]  font-medium">
                      {converNameChain(monsterDetail?.born_of_1_chain)}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[16px]  font-bold">
                      {t('monsterDetail.other.chainId')}
                    </div>
                    <div className="text-[16px]  font-medium">
                      {monsterDetail?.born_of_1_chain}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[16px]  font-bold">
                      {t('monsterDetail.other.collection')}
                    </div>
                    <div className="text-[16px]  font-medium">
                      {/* {converNameCollection(monsterDetail?.main_seed)} */}
                      {monsterDetail?.mint_type === 1
                        ? monsterDetail?.collaborationNft
                        : getCollectionMonster(monsterDetail.mint_type)}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[16px]  font-bold">
                      {t('monsterDetail.other.contract')}
                    </div>
                    <div className="text-[16px]  font-medium">
                      {formatAddress(monsterDetail?.born_of_1_address)}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[16px]  font-bold">
                      {t('monsterDetail.other.tokenId')}
                    </div>
                    <div className="text-[16px]  font-medium">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${configs.NETWORK.scan}token/${
                          monsterDetail?.contractAddress ??
                          configs.ADDRESS_MONSTER
                        }/instance/${monsterDetail?.monster_nft_id}`}
                        style={{ color: '#40A9FF' }}
                      >
                        {monsterDetail?.monster_nft_id}
                      </a>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-[16px]  font-bold">
                      {t('monsterDetail.other.image')}
                    </div>
                    <div className="text-[16px]  font-medium">
                      <img
                        style={{
                          width: 140,
                          height: 140,
                          objectFit: 'contain',
                        }}
                        // src={`../imgs/logoMonster/${converImageCollection(
                        //   monsterDetail?.main_seed,
                        // )}.png`}
                        src={`${
                          process.env.REACT_APP_BASE_URL_IMAGE
                        }/public/image/image/hash/${convertImageMonsterOther(
                          monsterDetail?.monster_script_id,
                        )}.png`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {monsterDetail?.mint_type === 10 && (
          <>
            {/* Fusion from */}
            <div className="">
              <div className="flex mb-4 items-center justify-between">
                <div className=" text-[24px]">
                  {t('monsterDetail.other.fusionFrom')}
                </div>

                <div
                  className="flex items-center common__btn_seedtre relative cursor-pointer"
                  onClick={() => {
                    showModalCheckout()
                  }}
                >
                  <div className="relative flex common__btn_main">
                    <div
                      className="polygon__outside relative z-10 w-[112px] h-[43px] flex items-center justify-center bg-[#ADEA00]"
                      style={{
                        clipPath:
                          'polygon(93% 100%, 100% 77%, 100% 22%, 92% 0%, 8% 0%, 0% 24%, 0% 76%, 8% 100%) ',
                        padding: 12,
                      }}
                    >
                      <div
                        className={`polygon__inside flex items-center ${'active__seedtre'}`}
                      >
                        <div className="relative whitespace-nowrap text-center w-full flex items-center justify-center">
                          <ICON_TREE />{' '}
                          <div className="ml-1">
                            {' '}
                            {t('common.btn.seedTree')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {monsterDetail?.seedTrees[0] && (
                  <div className="bg-[#020f38] p-[20px] rounded-[8px]">
                    <div className="flex flex-col gap-4">
                      {/* <Link to={linkPathMain}>
                        <div className="flex items-center justify-center w-full">
                          <div className="border border-[#FFFFFF] rounded-[8px] h-[30px] w-[120px] flex items-center justify-center">
                            <div className="text-[22px]  mr-2">Main</div>
                            <img src="../svg/book.svg" alt="" />
                          </div>
                        </div>
                      </Link> */}
                      <a
                        href={linkPathMain}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="flex items-center justify-center w-full">
                          <div className="border border-[#FFFFFF] rounded-[8px] h-[30px] w-[120px] flex items-center justify-center">
                            <div className="text-[22px]  mr-2">Main</div>
                            <img src="../svg/book.svg" alt="" />
                          </div>
                        </div>
                      </a>
                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.ID')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {monsterDetail?.seedTrees[0]?.monster_nft_id}
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.name')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {monsterDetail?.seedTrees[0]?.monster_name}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.monster')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {convertNameMonsterFusion(
                            monsterDetail?.seedTrees[0]?.monster_script_id,
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.mainSeed')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {/* {monsterDetail?.seedTrees[0]?.main_seed} */}
                          {converNamePublicMainSubSeed(
                            extractConvertMonsterSeed(
                              monsterDetail?.seedTrees[0]?.monster_script_id,
                            ).mainSeed,
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.subSeed')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {/* {monsterDetail?.seedTrees[0]?.sub_seed} */}
                          {converNamePublicMainSubSeed(
                            extractConvertMonsterSeed(
                              monsterDetail?.seedTrees[0]?.monster_script_id,
                            ).subSeed,
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.rank')}
                        </div>
                        <img
                          style={{
                            width: 14,
                            height: 14,
                            objectFit: 'contain',
                          }}
                          src={`../svg/rankTagText/${getRankbyValue(
                            monsterDetail?.seedTrees[0]?.monster_rank,
                          )}.svg`}
                          alt=""
                        />
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.overall')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {/* {handleCalculateOverall(
                            monsterDetail?.seedTrees[0]?.alter_basic_p?.health +
                              monsterDetail?.seedTrees[0]?.alter_basic_p
                                ?.strength +
                              monsterDetail?.seedTrees[0]?.alter_basic_p
                                ?.intelligent +
                              monsterDetail?.seedTrees[0]?.alter_basic_p
                                ?.dexterity +
                              monsterDetail?.seedTrees[0]?.alter_basic_p
                                ?.agility +
                              monsterDetail?.seedTrees[0]?.alter_basic_p
                                ?.vitality,

                            monsterDetail?.seedTrees[0]?.mov,
                            monsterDetail?.seedTrees[0]?.spd,
                            monsterDetail?.seedTrees[0]?.st,
                            (buffData[key1]?.element_resistance?.total +
                              buffData[key1]?.debuff_resistance?.total) /
                              10,
                            calculateIt(
                              monsterDetail?.seedTrees[0]
                                ?.innate_trait_id_list[0]?.innate_trait_id,
                              monsterDetail?.seedTrees[0]
                                ?.innate_trait_id_list[1]?.innate_trait_id,
                            ),
                            monsterDetail?.seedTrees[0]
                              ?.monster_acquired_traits[0]?.trait_level,
                            monsterDetail?.seedTrees[0]
                              ?.monster_acquired_traits[1]?.trait_level,
                          )} */}
                          {formatNumberOreral(
                            monsterDetail?.seedTrees[0]?.overall,
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {monsterDetail?.seedTrees[1] && (
                  <div className="bg-[#020f38] p-[20px] rounded-[8px]">
                    <div className="flex flex-col gap-4">
                      {/* <Link to={linkPathSub}>
                        <div className="flex items-center justify-center w-full">
                          <div className="border border-[#FFFFFF] rounded-[8px] h-[30px] w-[120px] flex items-center justify-center">
                            <div className="text-[22px]  mr-2">Sub</div>
                            <img src="../svg/book.svg" alt="" />
                          </div>
                        </div>
                      </Link> */}
                      <a
                        href={linkPathSub}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="flex items-center justify-center w-full">
                          <div className="border border-[#FFFFFF] rounded-[8px] h-[30px] w-[120px] flex items-center justify-center">
                            <div className="text-[22px] mr-2">Sub</div>
                            <img src="../svg/book.svg" alt="" />
                          </div>
                        </div>
                      </a>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.ID')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {monsterDetail?.seedTrees[1]?.monster_nft_id}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.name')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {monsterDetail?.seedTrees[1]?.monster_name}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.monster')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {convertNameMonsterFusion(
                            monsterDetail?.seedTrees[1]?.monster_script_id,
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.mainSeed')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {/* {monsterDetail?.seedTrees[1]?.main_seed} */}
                          {converNamePublicMainSubSeed(
                            extractConvertMonsterSeed(
                              monsterDetail?.seedTrees[1]?.monster_script_id,
                            ).mainSeed,
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.subSeed')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {' '}
                          {/* {monsterDetail?.seedTrees[1]?.sub_seed} */}
                          {converNamePublicMainSubSeed(
                            extractConvertMonsterSeed(
                              monsterDetail?.seedTrees[1]?.monster_script_id,
                            ).subSeed,
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.rank')}
                        </div>
                        <img
                          style={{
                            width: 14,
                            height: 14,
                            objectFit: 'contain',
                          }}
                          src={`../svg/rankTagText/${getRankbyValue(
                            monsterDetail?.seedTrees[1]?.monster_rank,
                          )}.svg`}
                          alt=""
                        />
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-[16px]  font-bold">
                          {t('monsterDetail.other.overall')}
                        </div>
                        <div className="text-[16px]  font-medium">
                          {/* {handleCalculateOverall(
                            monsterDetail?.seedTrees[1]?.alter_basic_p?.health +
                              monsterDetail?.seedTrees[1]?.alter_basic_p
                                ?.strength +
                              monsterDetail?.seedTrees[1]?.alter_basic_p
                                ?.intelligent +
                              monsterDetail?.seedTrees[1]?.alter_basic_p
                                ?.dexterity +
                              monsterDetail?.seedTrees[1]?.alter_basic_p
                                ?.agility +
                              monsterDetail?.seedTrees[1]?.alter_basic_p
                                ?.vitality,

                            monsterDetail?.seedTrees[1]?.mov,
                            monsterDetail?.seedTrees[1]?.spd,
                            monsterDetail?.seedTrees[1]?.st,
                            (buffData[key2]?.element_resistance?.total +
                              buffData[key2]?.debuff_resistance?.total) /
                              10,
                            calculateIt(
                              monsterDetail?.seedTrees[1]
                                ?.innate_trait_id_list[0]?.innate_trait_id,
                              monsterDetail?.seedTrees[1]
                                ?.innate_trait_id_list[1]?.innate_trait_id,
                            ),
                            monsterDetail?.seedTrees[1]
                              ?.monster_acquired_traits[0]?.trait_level,
                            monsterDetail?.seedTrees[1]
                              ?.monster_acquired_traits[1]?.trait_level,
                          )} */}
                          {formatNumberOreral(
                            monsterDetail?.seedTrees[1]?.overall,
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <SeedTreModal
        t={t}
        monsterDetail={monsterDetail}
        action={{
          isModalOpen: isModalOpen,
          handleCancel: handleCancel,
        }}
      />
    </Card>
  )
}

export default OtherMonster
