/**
 * Constructor
 */
const conf = {
  /**
   * Development configurations
   */
  development: {
    rpc: 'https://rpc-amoy.polygon.technology/',
    chainId: 80002,
    scan: "https://www.oklink.com/amoy"
  },

  production: {
    rpc: 'https://polygon.llamarpc.com/',
    chainId: 137,
    scan: "https://polygonscan.com/"
  }
}

/**
 * Module exports
 */
export default conf;
