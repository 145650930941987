export const rankTagData = ['6', '5', '4', '3', '2', '1', '0']
export const rankData = ['S', 'A', 'B', 'C', 'D', 'E', 'F']
export const parameterData = [
  'health',
  'strength',
  'intelligent',
  'dexterity',
  'agility',
  'vitality',
]
export const mainSeedData = [
  'Faireaf',
  'ODENPETS',
  'Icelime',
  'Golem',
  'UPA',
  'Ame-chan',
  'Tofu',
  'Hiyori',
  'Kids Hero',
  'Unicorn',
  'Yamato',
  'Kids Villain',
  'Valius',
  'DEKAUSA',
  'Sake Barrel PlanetMan',
  'AstarCats',
  'CCT',
  'P1',
  'SignWitch',
  'Ossan Paradise',
  'Layla',
]
export const seedData = [
  {
    name: 'Faireaf',
    value: 'Faireaf',
  },
  {
    name: 'ODENPETS',
    value: 'ODENPETS(ODENDAO)',
  },
  {
    name: 'Icelime',
    value: 'Icelime(EIEN)',
  },
  {
    name: 'Golem',
    value: 'Golem',
  },
  {
    name: 'UPA',
    value: 'UPA(Debreed)',
  },
  {
    name: 'Ame-chan',
    value: 'Ame-chan(KawaiiGirl)',
  },
  {
    name: 'Tofu',
    value: 'Tofu',
  },
  {
    name: 'Hiyori',
    value: 'Hiyori(Wafuku)',
  },
  {
    name: 'Kids Hero',
    value: 'Kids Hero(Kids Fight)',
  },
  {
    name: 'Unicorn',
    value: 'Unicorn',
  },
  {
    name: 'Yamato',
    value: 'Yamato(1912)',
  },
  {
    name: 'Kids Villain',
    value: 'Kids Villain(Kids Fight)',
  },
  {
    name: 'Valius',
    value: 'Valius',
  },
  {
    name: 'DEKAUSA',
    value: 'DEKAUSA(ALKEMON)',
  },
  {
    name: 'Sake Barrel PlanetMan',
    value: 'Sake Barrel PlanetMan(PlanetmMan)',
  },
  {
    name: 'AstarCats',
    value: 'AstarCats',
  },
  {
    name: 'CCT',
    value: 'CCT(candyconytown)',
  },
  {
    name: 'P1',
    value: 'P-1(X-soldiers)',
  },
  {
    name: 'SignWitch',
    value: 'SignWitch(Astar Sign Witch)',
  },
  {
    name: 'Ossan Paradise',
    value: 'Ossan Paradise',
  },
  {
    name: 'Layla',
    value: 'Layla(Love Addicted Girls)',
  },
]

export const basicParameterData = [
  {
    type: 'health',
    rank: rankData,
  },
  {
    type: 'strength',
    rank: rankData,
  },
  {
    type: 'intelligent',
    rank: rankData,
  },
  {
    type: 'dexterity',
    rank: rankData,
  },
  {
    type: 'agility',
    rank: rankData,
  },
  {
    type: 'vitality',
    rank: rankData,
  },
]

export const growthPotentialData = [
  {
    type: 'hp',
    rank: rankData,
  },
  {
    type: 'str',
    rank: rankData,
  },
  {
    type: 'int',
    rank: rankData,
  },
  {
    type: 'dex',
    rank: rankData,
  },
  {
    type: 'agi',
    rank: rankData,
  },
  {
    type: 'vit',
    rank: rankData,
  },
]
export const growthTypeData = ['Precocious', 'Normal', 'Late_Bloomer']
export const terrainCompatibilityData = [
  // {
  //   name: 'None',
  //   color: 'text-[#fff]',
  //   value: 0,
  // },
  {
    name: 'Plains',
    color: 'text-[#2ba30b]',
    value: 1,
  },
  {
    name: 'Volcano',
    color: 'text-[#af381c]',
    value: 7,
  },
  {
    name: 'Forest',
    color: 'text-[#9ea941]',
    value: 2,
  },
  {
    name: 'Desert',
    color: 'text-[#e8d79a]',
    value: 4,
  },
  {
    name: 'Mountain',
    color: 'text-[#9d6848]',
    value: 6,
  },
  {
    name: 'Wasteland',
    color: 'text-[#ea91fe]',
    value: 5,
  },
  {
    name: 'Waterfront',
    color: 'text-[#4bc2fe]',
    value: 3,
  },
]

export const skillTypeData = [
  {
    name: 'STR',
    color: 'text-[#FF6262]',
  },
  {
    name: 'Heal',
    color: 'text-[#76CC7A]',
  },
  {
    name: 'INT',
    color: 'text-[#CC76CC]',
  },
  {
    name: 'Support',
    color: 'text-[#60ABFE]',
  },
]

export const specialtyElementData = [
  {
    name: 'Strike',
    value: 'Blunt',
    color: 'text-[#D67D38]',
  },
  {
    name: 'Slash',
    value: 'Slash',
    color: 'text-[#95e5fa]',
  },
  {
    name: 'Thrust',
    value: 'Thrust',
    color: 'text-[#ccd56f]',
  },
  {
    name: 'Fire',
    value: 'Fire',
    color: 'text-[#ec5249]',
  },
  {
    name: 'Water',
    value: 'Water',
    color: 'text-[#4cc1ff]',
  },
  {
    name: 'Wind',
    value: 'Wind',
    color: 'text-[#b7c3ca]',
  },
  {
    name: 'Earth',
    value: 'Earth',
    color: 'text-[#e29b6d]',
  },
]

export const personalityData = [
  'Cool',
  'Curious',
  'Serious',
  'Jolly',
  'Devoted',
  'Dreamer',
  'Gentle',
  'Foodie',
  'Innocent',
  'Lazy',
  'Brave',
  'Graceful',
  'Naive',
  'Emotional',
  'Panicky',
  'Shy',
  'Adventurer',
  'Optimist',
  'Stubborn',
  'Loyal',
]

export const innateTraitsEffectData = [
  'Debuff',
  'Element',
  'Resistance',
  'Heal',
  'Raise',
  'Buff',
  'AVD',
  'HIT',
  'ST',
  'CRI',
  'SPD',
  'Exploration',
  'Damage_Reduction',
  'Damage_Increase',
  'Other',
]
export const acquiredTraitsEffectData = [
  'Basic_Parameter',
  'CRI',
  'ST',
  'HIT',
  'Debuff',
  'SPD',
  'AVD',
  'Resistance',
  'Element',
]
