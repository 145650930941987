import { Card } from 'antd'
import { formatItemName } from '~/helpers/common'

export const ParameterItem = (props) => {
  const { t, itemDetail } = props
  // Reusable render function
  function renderItemDetail(key) {
    if (itemDetail?.[key] && itemDetail?.[key] !== 0) {
      return (
        <div className="flex justify-between">
          <div className="text-[16px]">{t(`itemDetail.${key}`)}</div>
          <div
            className={`text-[16px] ${
              itemDetail[key] > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
            }`}
          >
            {itemDetail[key] > 0 ? '+' : ''}
            {itemDetail[key]}
          </div>
        </div>
      )
    }
    return null
  }

  // Check if any of the fields have valid values
  function shouldShowDescription() {
    const fields = [
      'recovery_stress',
      'recovery_fatigue',
      'training_regimen',
      'training_policy',
      'physical_build',
      'physical',
      'recover_lifespan',
      'degree_of_friendship',
      'condition',
      'body',
      'energy',
      'agi',
      'dex',
      'farm_hp',
      'farm_str',
      'farm_int',
      'farm_dex',
      'farm_agi',
      'farm_vit',
      'fatigue_reduction',
      'hp',
      'int',
      'lifespan',
      'vit',
      'str',
      'rest_enhancement',
      'stress_reduction',
    ]

    return !fields.some(
      (field) => itemDetail?.[field] && itemDetail?.[field] !== 0,
    )
  }
  return (
    <Card className="w-full">
      <div className="flex gap-4">
        <div className="flex-1 bg-[#020f38] py-[16px] px-[24px] min-h-[142px] rounded-[8px] font__M__plus font-bold">
          {itemDetail?.item_type === 'TOURNAMENT_TICKET_UC' && (
            <div>
              <div className="text-[16px]">{t('itemDetail.desticket1')}</div>
              <div className="text-[16px]">{t('itemDetail.desticket2')}</div>
            </div>
          )}

          {itemDetail?.item_type === 'EXPLORATION_TICKET_UC' && (
            <div>
              <div className="text-[16px]">{t('itemDetail.desticket3')}</div>
            </div>
          )}
          {itemDetail?.description && itemDetail?.type === 'Fusion_Item' && (
            <div className="flex justify-between">
              <div className="text-[16px]">{itemDetail.description}</div>
            </div>
          )}

          {renderItemDetail('recovery_fatigue')}
          {renderItemDetail('recovery_stress')}
          {renderItemDetail('physical')}
          {renderItemDetail('training_policy')}
          {renderItemDetail('degree_of_friendship')}
          {renderItemDetail('training_regimen')}
          {renderItemDetail('physical_build')}
          {renderItemDetail('recover_lifespan')}
          {renderItemDetail('condition')}
          {renderItemDetail('body')}
          {renderItemDetail('energy')}
          {renderItemDetail('agi')}
          {renderItemDetail('dex')}
          {renderItemDetail('farm_hp')}
          {renderItemDetail('farm_str')}
          {renderItemDetail('farm_int')}
          {renderItemDetail('farm_dex')}
          {renderItemDetail('farm_agi')}
          {renderItemDetail('farm_vit')}
          {renderItemDetail('fatigue_reduction')}
          {renderItemDetail('hp')}
          {renderItemDetail('int')}
          {renderItemDetail('lifespan')}
          {renderItemDetail('vit')}
          {renderItemDetail('str')}
          {renderItemDetail('rest_enhancement')}
          {renderItemDetail('stress_reduction')}

          {shouldShowDescription() && itemDetail?.description && (
            <div className="flex justify-between">
              {itemDetail?.item_type === 'TOURNAMENT_TICKET_UC' ||
              itemDetail?.item_type === 'EXPLORATION_TICKET_UC' ||
              itemDetail?.type === 'Fusion_Item' ? (
                <div className="text-[16px]"></div>
              ) : (
                <div className="text-[16px]">
                  {itemDetail?.item_type === 'TOURNAMENT_TICKET_B' ? (
                    <div>
                      <div className="text-[16px]">
                        {t('itemDetail.desticket1')}
                      </div>
                      <div className="text-[16px]">
                        {t('itemDetail.desticket4')}
                      </div>
                    </div>
                  ) : (
                    <>
                      {itemDetail?.type === 'Regeneration_Hash'
                        ? itemDetail.description.replace(
                            'a specific Monster',
                            formatItemName(itemDetail.name),
                          )
                        : itemDetail.description}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex-1 bg-[#020f38] py-[16px] px-[24px] rounded-[8px]">
          <div className="flex justify-between items-center font__M__plus font-bold">
            <div className="text-[16px]">{t('itemDetail.supply')}</div>
            <div className="text-[16px] text-[#FAAD14]">
              {itemDetail?.totalAmount}/
              {itemDetail?.unlimited === true
                ? 'unlimited'
                : itemDetail?.amountLimit}
            </div>
          </div>
          {itemDetail?.type === 'Regeneration_Hash' && itemDetail.main_seed && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.mainSeed')}
                </div>
                <div className="text-[16px]">{itemDetail?.main_seed}</div>
              </div>
            </>
          )}
          {itemDetail?.type === 'Regeneration_Hash' && itemDetail.main_seed && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.subSeed')}
                </div>
                <div className="text-[16px]">{itemDetail?.main_seed}</div>
              </div>
            </>
          )}

          {itemDetail?.item_type === 'REGENERATION_HASH_RANDOM_R' && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.mainSeed')}
                </div>
                <div className="text-[16px]">Random</div>
              </div>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.subSeed')}
                </div>
                <div className="text-[16px]">Random</div>
              </div>
            </>
          )}
          {itemDetail?.type === 'Regeneration_Hash' && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.regenerationCount')}
                </div>
                <div className="text-[16px]">0/1</div>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  )
}
