import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import { BoxCardOpen } from '../shared-components/Card/BoxCardOpen'
import { SharedOnTwitter } from '../shared-components/shared-twitter'
import { converImageOpenBox } from '~/helpers/Species'

export default function OpenSuccess(props) {
  const { action, t, data, loadingModal } = props
  const [animationEnded, setAnimationEnded] = useState(false)

  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#1890FF',
    height: '48px',
    borderRadius: '4px',
  }

  useEffect(() => {
    if (action?.isModalOpen) {
      const animationDuration = 5000 // Example: 5 seconds
      const timer = setTimeout(() => setAnimationEnded(true), animationDuration)
      return () => clearTimeout(timer)
    } else {
      setAnimationEnded(false)
    }
  }, [action?.isModalOpen])

  return (
    <>
      <Modal
        key={data?.type}
        centered
        title={
          <h3 className="flex justify-center text-xl font-bold">
            {t('modal.open_success.title', { name: data?.asset })}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          animationEnded ? (
            <div key="buttons" className="flex justify-center">
              {/* <Button
                key="ok"
                type="primary"
                onClick={() => action?.handleOk()}
                style={okButtonStyle}
                loading={loadingModal}
              >
                {t('modal.open_success.share_x')}
              </Button> */}
              <SharedOnTwitter
                title={`${data?.asset}\n${data?.type}`}
                okButtonStyle={okButtonStyle}
                urlShareTwitter={`\n${
                  process.env.REACT_APP_BASE_URL_IMAGE
                }/public/image/image/hash/${converImageOpenBox(
                  data?.type,
                )}.png`}
              />
            </div>
          ) : null,
        ]}
      >
        {!animationEnded ? (
          <div className="flex justify-center fade-in">
            <img
              src={`${process.env.PUBLIC_URL}/imgs/video/${
                data?.asset === 'GENERAL BOX' ? 'generalbox' : 'genesisbox'
              }.png`}
              // src={`${process.env.REACT_APP_BASE_URL}/assets/default/genesisbox.png`}
              alt="Animated open box"
              className={animationEnded ? 'hidden' : ''}
              width={445}
            />
          </div>
        ) : (
          <>
            <div className="text-center pb-5 text-[16px] fade-in">
              {t('modal.open_success.description', {
                name: data?.asset,
                group: data?.type,
              })}
            </div>
            <div className="flex justify-center fade-in">
              <BoxCardOpen data={data} />
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
