import { CaretUpOutlined } from '@ant-design/icons'
import { Col, Row, Collapse, InputNumber, Slider } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import { mainSeedData } from '../../Home/components/dataFilter'
import { categoryHashData } from '../data/dataHash'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTE } from '~/views/routes/config'
export const FilterHashNFT = (props) => {
  const { t } = useTranslation()
  const onChange = (newValue) => {
    setRegenerationCount(newValue)
  }
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const {
    classCustom,
    mainSeedFilter,
    category,
    regenerationCount,
    setMainSeedFilter,
    setCategory,
    setRegenerationCount,
    page,
    pageSize,
  } = props
  const location = useLocation()

  const updateLocalStorageHash = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  useEffect(() => {
    const pathMap = {
      [ROUTE.PROFILE_HASH_SALES.PATH]: 'hashNFTMyCreated',
      [ROUTE.HASH_NFT.PATH]: 'hashNFTMarketplace',
    }
    const combinedData = {
      mainSeedFilter,
      category,
      regenerationCount,
      page,
      pageSize,
    }
    updateLocalStorageHash(pathMap[location.pathname], combinedData)
  }, [
    mainSeedFilter,
    category,
    regenerationCount,
    page,
    pageSize,
    location.pathname,
  ])
  const clearAll = () => {
    setMainSeedFilter([])
    setCategory([])
    setRegenerationCount([0, 5]) // Reset to default values
  }

  return (
    <div
      className={`${
        classCustom ?? 'filter__common'
      } bg-[#0d2758] p-[16px] max-w-[372px] w-[372px]`}
    >
      {isMobile ? (
        <></>
      ) : (
        <>
          <Row className="">
            <Col span={12}>
              <div className="text-[16px]"> {t('filter')}</div>
            </Col>
            <Col span={12} className="flex justify-end">
              <div
                className="text-[16px] text-[#FAAD14] cursor-pointer"
                onClick={() => {
                  clearAll()
                }}
              >
                {t('clearAll')}
              </div>
            </Col>
          </Row>

          <hr className="my-5" />
        </>
      )}

      <Collapse
        expandIconPosition={'end'}
        defaultActiveKey={['mainSeed', 'category', 'regenerationCount']}
        items={[
          //mainSeed
          {
            key: 'mainSeed',
            label: t('common.filter.tag.mainSeed'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {mainSeedData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      mainSeedFilter?.find((rank) => rank === data) &&
                      'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      mainSeedFilter?.find((rank) => rank === data)
                        ? setMainSeedFilter(
                            mainSeedFilter.filter((rank) => rank !== data),
                          )
                        : setMainSeedFilter((oldRank) => [...oldRank, data])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {data}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //category
          {
            key: 'category',
            label: t('common.filter.tag.category'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {categoryHashData?.map((data, i) => (
                  <div
                    key={i?.toString()}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      category?.find((item) => item.name === data?.name) &&
                      'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      category?.find((item) => item.name === data?.name)
                        ? setCategory(
                            category.filter((item) => item.name !== data?.name),
                          )
                        : setCategory((oldItem) => [...oldItem, data])
                    }}
                  >
                    <div
                      className={`${data?.color} font__M__plus font-bold`}
                      style={{ fontWeight: 700 }}
                    >
                      {t(`common.filter.category.${data?.name}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //regenerationCount
          {
            key: 'regenerationCount',
            label: t('common.filter.tag.regenerationCount'),
            children: (
              <div>
                <div className="w-full font__M__plus font-medium">
                  <Slider
                    style={{ fontFamily: 'M plus', fontWeight: 'medium' }}
                    onChange={onChange}
                    range
                    step={1}
                    min={0}
                    max={5}
                    value={regenerationCount}
                  />

                  <div className="flex justify-between items-center mb-4">
                    <div className="text-[12px]">0</div>
                    <div className="text-[12px]"> 5</div>
                  </div>

                  <Row className="flex items center justify-between">
                    <Col span={11}>
                      <InputNumber
                        style={{ fontFamily: 'M plus', fontWeight: 'medium' }}
                        className="h-[44px] w-full"
                        defaultValue={regenerationCount[0]}
                        value={regenerationCount[0]}
                        min={0}
                        max={regenerationCount[1]}
                        onChange={(value) => {
                          setRegenerationCount([
                            value === null ? 0 : parseFloat(value),
                            regenerationCount[1],
                          ])
                        }}
                      />
                    </Col>
                    <Col span={1} className="flex items-center justify-center">
                      -
                    </Col>
                    <Col span={11}>
                      <InputNumber
                        min={regenerationCount[0]}
                        max={5}
                        className="h-[44px] w-full"
                        defaultValue={regenerationCount[1]}
                        value={regenerationCount[1]}
                        onChange={(value) =>
                          setRegenerationCount([
                            regenerationCount[0],
                            value === null ? 0 : parseFloat(value),
                          ])
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            ),
          },
        ]}
        expandIcon={({ isActive }) => (
          <CaretUpOutlined
            style={{ fontSize: 16 }}
            rotate={isActive ? 0 : 180}
          />
        )}
      />
    </div>
  )
}
