import { ContractMarketplace } from '~/blockchain/contract'
import { fromWei } from '~/blockchain/provider'

export const convertPrice = (price, tema) => {
  return (parseFloat(price) / parseFloat(tema)).toFixed(3)
}

export const getFeeMKP = async () => {
  return fromWei(await ContractMarketplace.feeSeller())
}

export const convertOasToUSD = (price, tema) => {
  if (!price || !tema) return undefined
  return (parseFloat(price) * parseFloat(tema)).toFixed(3)
}

export const formatNumber = (number) => {
  if (!number) return 0

  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 3,
  }).format(number)

  const trimmedNumber = formattedNumber.replace(/\.0{3}$/, '')

  return trimmedNumber.replace('$', '')
}

export const formatAddress = (address) => {
  return address?.slice(0, 6) + '...' + address?.slice(-4)
}

export const formatTransaction = (txHash) => {
  return txHash.slice(0, 20) + '...'
}

export const formatLongText = (text) => {
  return text?.length > 10 ? text.slice(0, 10) + '...' : text
}

export function getItemMenu(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

export const getMintType = (type) => {
  switch (type) {
    case 0:
      return 'Regeneration'
    case 1:
      return 'Regeneration'
    case 2:
      return 'Free'
    case 3:
      return 'Regeneration'
    case 4:
      return 'Regeneration'
    case 5:
      return 'Regeneration'
    case 6:
      return 'Regeneration'
    case 7:
      return 'Fusion Regeneration'
    case 8:
    case 9:
      return 'Fusion Regeneration'
    case 10:
      return 'Fusion'
    case 11:
      return 'Fusion Regeneration'
    default:
      break
  }
}

export function convertAddressToProperFormat(address) {
  if (typeof address === 'string') {
    const prefix = address.slice(0, 2)
    const hexBody = address.slice(2)
    const upperCaseHexBody = hexBody.toUpperCase()
    const newaddress = prefix + upperCaseHexBody
    return newaddress
  }
  return null
}
export function formatNumberOreral(number) {
  if (number % 1 === 0) {
    return parseInt(number) // Convert to integer
  } else {
    return parseFloat(number.toFixed(0)) // Round to two decimal places
  }
}

// thêm dấu () vào name monster
export function formatItemName(name) {
  const regex = /(.*?)(\d+)$/
  const match = name?.match(regex)
  if (match) {
    return `${match[1]} (${match[2]})`
  }
  return name
}
