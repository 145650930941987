import { put, takeLatest } from 'redux-saga/effects'

import { logoutFailed, logoutSuccess, LOGOUT_START } from '../../actions/auth'
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  USER_INFO,
} from '~/common/constants/ApiConstant'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doLogout(action) {
  try {
    // Remove wallet from local storage
    localStorage.removeItem('wallet')

    // Remove token from local storage
    localStorage.removeItem(AUTH_TOKEN)

    // Remove refresh token from local storage
    localStorage.removeItem(REFRESH_TOKEN)

    // Remove user infomation from local storage
    localStorage.removeItem(USER_INFO)

    localStorage.removeItem('token')

    // Remove filter hash nft
    localStorage.removeItem('mainSeedFilterHashNFTMarketplace')
    localStorage.removeItem('mainSeedFilterHashNFTMyCreated')
    localStorage.removeItem('mainSeedFilterHashNFTMyAssets')

    localStorage.removeItem('categoryHashNFTMarketplace')
    localStorage.removeItem('categoryHashNFTMyCreated')
    localStorage.removeItem('categoryHashNFTMyAssets')

    localStorage.removeItem('regenerationCountHashNFTMarketplace')
    localStorage.removeItem('regenerationCountHashNFTMyCreated')
    localStorage.removeItem('regenerationCountHashNFTMyAssets')

    // Remove filter monster
    localStorage.removeItem('rankTagFilterMonsterMarketplace')
    localStorage.removeItem('rankTagFilterMonsterMyCreated')
    localStorage.removeItem('rankTagFilterMonsterMyAssets')

    localStorage.removeItem('mainSeedFilterMonsterMarketplace')
    localStorage.removeItem('mainSeedFilterMonsterMyCreated')
    localStorage.removeItem('mainSeedFilterMonsterMyAssets')

    localStorage.removeItem('subSeedFilterMonsterMarketplace')
    localStorage.removeItem('subSeedFilterMonsterMyCreated')
    localStorage.removeItem('subSeedFilterMonsterMyAssets')

    localStorage.removeItem('basicParameterMonsterMarketplace')
    localStorage.removeItem('basicParameterMonsterMyCreated')
    localStorage.removeItem('basicParameterMonsterMyAssets')

    localStorage.removeItem('growthPotentialMonsterMarketplace')
    localStorage.removeItem('growthPotentialMonsterMyCreated')
    localStorage.removeItem('growthPotentialMonsterMyAssets')

    localStorage.removeItem('growthTypeMonsterMarketplace')
    localStorage.removeItem('growthTypeMonsterMyCreated')
    localStorage.removeItem('growthTypeMonsterMyAssets')

    localStorage.removeItem('valueLifeSpanMonsterMarketplace')
    localStorage.removeItem('valueLifeSpanMonsterMyCreated')
    localStorage.removeItem('valueLifeSpanMonsterMyAssets')

    localStorage.removeItem('terrainCompatibilityMonsterMarketplace')
    localStorage.removeItem('terrainCompatibilityMonsterMyCreated')
    localStorage.removeItem('terrainCompatibilityMonsterMyAssets')

    localStorage.removeItem('skillTypeMonsterMarketplace')
    localStorage.removeItem('skillTypeMonsterMyCreated')
    localStorage.removeItem('skillTypeMonsterMyAssets')

    localStorage.removeItem('specialtyElementMonsterMarketplace')
    localStorage.removeItem('specialtyElementMonsterMyCreated')
    localStorage.removeItem('specialtyElementMonsterMyAssets')

    localStorage.removeItem('personalityMonsterMarketplace')
    localStorage.removeItem('personalityMonsterMyCreated')
    localStorage.removeItem('personalityMonsterMyAssets')

    localStorage.removeItem('innateTraitsByEffectMonsterMarketplace')
    localStorage.removeItem('innateTraitsByEffectMonsterMyCreated')
    localStorage.removeItem('innateTraitsByEffectMonsterMyAssets')

    localStorage.removeItem('acquiredTraitsByEffectMonsterMarketplace')
    localStorage.removeItem('acquiredTraitsByEffectMonsterMyCreated')
    localStorage.removeItem('acquiredTraitsByEffectMonsterMyAssets')

    // Remove filter farm
    localStorage.removeItem('terrainCompatibilityMarketplace')
    localStorage.removeItem('terrainCompatibilityMyCreated')
    localStorage.removeItem('terrainCompatibilityMyAssets')

    localStorage.removeItem('boostParameterMarketplace')
    localStorage.removeItem('boostParameterMyCreated')
    localStorage.removeItem('boostParameterMyAssets')

    localStorage.removeItem('boostPotensialMarketplace')
    localStorage.removeItem('boostPotensialMyCreated')
    localStorage.removeItem('boostPotensialMyAssets')

    localStorage.removeItem('specialTrainningMarketplace')
    localStorage.removeItem('specialTrainningMyCreated')
    localStorage.removeItem('specialTrainningMyAssets')

    localStorage.removeItem('farmTraitMarketplace')
    localStorage.removeItem('farmTraitMyCreated')
    localStorage.removeItem('farmTraitMyAssets')

    // Remove filter item && itemMyAssets
    localStorage.removeItem('categoryItemsMarketplace')
    localStorage.removeItem('categoryItemsMyCreated')
    localStorage.removeItem('categoryItemsMyAssets')

    localStorage.removeItem('effectItemsMarketplace')
    localStorage.removeItem('effectItemsMyCreated')
    localStorage.removeItem('effectItemsMyAssets')

    localStorage.removeItem('qualityItemsMarketplace')
    localStorage.removeItem('qualityItemsMyCreated')
    localStorage.removeItem('qualityItemsMyAssets')

    localStorage.removeItem('mainSeedRegenerationItemsMarketplace')
    localStorage.removeItem('mainSeedRegenerationItemsMyCreated')
    localStorage.removeItem('mainSeedRegenerationItemsMyAssets')

    yield put(logoutSuccess())
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess()
    }

    // const isTrigger = action?.callbackUrl

    // if (!isTrigger) {
    //   window.location.reload()
    // }
  } catch (error) {
    yield put(logoutFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

/**
 * Watch search users
 */
export default function* watchLogout() {
  yield takeLatest(LOGOUT_START, doLogout)
}
