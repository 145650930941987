import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/styles/antdCustom.css'
import './assets/styles/index.css'
import './assets/styles/animation.css'
import { MetaMaskProvider } from '@metamask/sdk-react'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <MetaMaskProvider
      debug={true}
      sdkOptions={{
        logging: {
          developerMode: true,
          sdk: true,
          remoteLayer: false,
          serviceLayer: false,
          plaintext: true,
        },
        preferDesktop: true,
        storage: {
          enabled: true,
        },
        checkInstallationImmediately: false, // This will automatically connect to MetaMask on page load
        dappMetadata: {
          name: 'Marketplace Re.Monster',
          url: window.location.host,
        },
        i18nOptions: {
          enabled: true,
        },
        i18nInstance: "jp",
      }}
    >
      <App />
    </MetaMaskProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
