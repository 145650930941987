import { ROUTE } from '~/views/routes/config'
import { ReMonsterTwoBorderSm } from '../../Button/ReMonsterTwoBorderSm'
import { useHistory, Link } from 'react-router-dom'
import { getSpeciesValue } from '~/helpers/Species'
import { convertOasToUSD, formatNumber } from '~/helpers/common'
import { getBgHash } from '~/helpers/get-color-parameter'
import { getNameItem, getNameItemByContract } from '~/helpers/ItemName'

export const HashCard = (props) => {
  const { data, button, tema, t, img } = props
  const history = useHistory()
  const handleLinkClick = (e) => {
    if (e.target.closest('.common__btn')) {
      e.preventDefault()
    }
  }
  return (
    <Link
      to={`${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${data?.tokenId}/${data?.contractAddress}/${data?.orderId}`}
      onClick={handleLinkClick}
      className=" hover:text-[#FFFFFF]"
    >
      <div
        className="card__monster w-[250px] h-[350px] cursor-pointer"
        // onClick={(e) => {
        //   if (!e.target.closest('.common__btn')) {
        //     history.push(
        //       `${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${data?.tokenId}/${data?.contractAddress}/${data?.orderId}`,
        //     )
        //   }
        // }}
      >
        <div className="relative flex items-center justify-center h-full">
          <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
            <div
              className="relative h-full flex flex-col p-[10px] text-[18px]"
              style={{
                background: getBgHash(data?.type_name),
              }}
            >
              {data?.type_name === 'Regeneration Hash' ? (
                <div className="flex absolute left-[2%] top-[2%]">
                  <img
                    className="mr-2 w-[32px] h-[32px] object-contain "
                    src={`../svg/qualityTag/${
                      data?.quality ??
                      getNameItemByContract(
                        data?.contractAddress,
                        data?.tokenId,
                      )?.quanlity
                    }.svg`}
                    alt=""
                  />
                  <div className="title__shop_card text-[14px] ">
                    {/* {getNameItem(3, data?.tokenId)?.name} */}
                    {data?.main_seed}
                  </div>
                </div>
              ) : (
                <div className="flex items-start flex-col relative">
                  <div className="title__card">
                    {data?.asset === 'HASH CHIP NFT'
                      ? data?.name
                      : data?.type_name}
                  </div>
                  <div className="title__card">{data?.main_seed}</div>
                  {data?.type_name === 'Genesis Hash' ||
                  data?.type_name === 'General Hash' ? (
                    <div className="py-2 absolute top-[100%]">
                      <div
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                          borderRadius: 4,
                        }}
                        className={`flex items-center justify-center min-w-[48px] h-[24px] text-[12px] rounded-[4px] px-2`}
                      >
                        {data ? `#${data?.tokenId}` : ''}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {data?.type_name === 'Hash Chip NFT' ? (
                    <div className="py-2 absolute top-[100%]">
                      <div
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                          borderRadius: 4,
                        }}
                        className={`flex items-center justify-center min-w-[48px] h-[24px] text-[12px] rounded-[4px] px-2`}
                      >
                        {data ? `#${data?.tokenId}` : ''}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <div className="h-full flex items-center justify-center">
                <img
                  alt=""
                  className={`card__monster_img object-contain ease-in-out duration-300 ${
                    data?.type_name === 'Hash Chip NFT'
                      ? 'w-[125px] h-[125px]'
                      : 'w-[160px] h-[160px]'
                  }`}
                  src={img}
                />
              </div>
              {data?.type_name === 'Regeneration Hash' ? (
                <div className="absolute bottom-[5px] w-[200px]">
                  <div className="text-[12px] flex w-full justify-between">
                    <div>{t('quality')}</div>
                    <div>{data?.quantity ?? data?.amount}</div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
              {data?.price && (
                <>
                  <div className="flex items-center justify-center">
                    <img
                      className="w-[16px] h-[16px] object-contain	"
                      src="../imgs/coins/oasis.svg"
                      alt=""
                    />
                    <div className="relative whitespace-nowrap flex ml-2">
                      {formatNumber(data?.price)}
                    </div>
                  </div>

                  <div className="text-[12px] mb-1">
                    ${formatNumber(convertOasToUSD(data?.price, tema?.tema))}
                  </div>
                </>
              )}

              <ReMonsterTwoBorderSm
                title={button?.title}
                functionButton={button?.functionButton}
                data={data}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
