import { CaretUpOutlined } from '@ant-design/icons'
import { Col, Row, Collapse } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import { CategoryData, colorTextEffect, effectData, qualityData } from './data'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'
import { useLocation } from 'react-router-dom'
import { ROUTE } from '~/views/routes/config'
import { useEffect } from 'react'
import { mainSeedData } from '~/views/app/Home/components/dataFilter'
export const FilterItems = (props) => {
  const {
    sort,
    page,
    pageSize,
    category,
    effect,
    quality,
    mainSeedFilterRegeneration,
    setCategory,
    setEffect,
    setQuality,
    setMainSeedFilterRegeneration,
    classCustom,
  } = props
  const { t } = useTranslation()

  const clearAll = () => {
    setCategory([])
    setEffect([])
    setQuality([])
    setMainSeedFilterRegeneration([])
  }
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })

  const capitalizeData = (data) => {
    const capitalizeSet = new Set(['hp', 'str', 'dex', 'agi', 'vit', 'int'])
    if (capitalizeSet.has(data)) {
      return data.toUpperCase()
    }
    return data
  }
  const location = useLocation()
  const updateLocalStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  // useEffect(() => {
  //   const pathMap = {
  //     [ROUTE.ITEM.PATH]: 'categoryItemsMarketplace',
  //     [ROUTE.PROFILE_ITEMS_SALES.PATH]: 'categoryItemsMyCreated',
  //   }
  //   updateLocalStorageItem(pathMap[location.pathname], category)
  // }, [category, location.pathname])

  // useEffect(() => {
  //   const pathMap = {
  //     [ROUTE.ITEM.PATH]: 'effectItemsMarketplace',
  //     [ROUTE.PROFILE_ITEMS_SALES.PATH]: 'effectItemsMyCreated',
  //   }
  //   updateLocalStorageItem(pathMap[location.pathname], effect)
  // }, [effect, location.pathname])

  // useEffect(() => {
  //   const pathMap = {
  //     [ROUTE.ITEM.PATH]: 'qualityItemsMarketplace',
  //     [ROUTE.PROFILE_ITEMS_SALES.PATH]: 'qualityItemsMyCreated',
  //   }
  //   updateLocalStorageItem(pathMap[location.pathname], quality)
  // }, [quality, location.pathname])
  // useEffect(() => {
  //   const pathMap = {
  //     [ROUTE.ITEM.PATH]: 'mainSeedRegenerationItemsMarketplace',
  //     [ROUTE.PROFILE_ITEMS_SALES.PATH]: 'mainSeedRegenerationItemsMyCreated',
  //   }
  //   updateLocalStorageItem(
  //     pathMap[location.pathname],
  //     mainSeedFilterRegeneration,
  //   )
  // }, [mainSeedFilterRegeneration, location.pathname])

  useEffect(() => {
    const pathMap = {
      [ROUTE.ITEM.PATH]: 'ItemsMarketplace',
      [ROUTE.PROFILE_ITEMS_SALES.PATH]: 'ItemsMyCreated',
    }
    const combinedData = {
      sort,
      category,
      effect,
      quality,
      mainSeedFilterRegeneration,
      page,
      pageSize,
    }
    updateLocalStorageItem(pathMap[location.pathname], combinedData)
  }, [
    sort,
    category,
    effect,
    quality,
    mainSeedFilterRegeneration,
    page,
    pageSize,
    location.pathname,
  ])

  const defaultActiveKey = ['category', 'effect', 'quality']
  if (mainSeedFilterRegeneration.length > 0) {
    defaultActiveKey.push('mainSeed')
  }
  return (
    <div
      className={`${
        classCustom ?? 'filter__common'
      } bg-[#0d2758] p-[16px] max-w-[372px] w-[372px]`}
    >
      {isMobile ? (
        <></>
      ) : (
        <>
          <Row className="">
            <Col span={12}>
              <div className="text-[16px]"> {t('filter')}</div>
            </Col>
            <Col span={12} className="flex justify-end">
              <div
                className="text-[16px] text-[#FAAD14] cursor-pointer"
                onClick={() => {
                  clearAll()
                }}
              >
                {t('clearAll')}
              </div>
            </Col>
          </Row>

          <hr className="my-5" />
        </>
      )}

      <Collapse
        defaultActiveKey={defaultActiveKey}
        expandIconPosition={'end'}
        items={[
          //category
          {
            key: 'category',
            label: t('common.filter.tag.category'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {CategoryData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      category?.find((rank) => rank === data) && 'bg-[#1950b3] '
                    }`}
                    // style={{
                    //   cursor:
                    //     data === 'Training_Item' || data === 'Fusion_Item'
                    //       ? 'not-allowed'
                    //       : 'pointer',
                    // }}
                    onClick={() => {
                      category?.find((rank) => rank === data)
                        ? setCategory(category.filter((rank) => rank !== data))
                        : setCategory((oldRank) => [...oldRank, data])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {t(`common.filter.category.${data}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //effect
          {
            key: 'effect',
            label: t('common.filter.tag.effect'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {effectData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] p-[10px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      effect?.find((rank) => rank === data) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      effect?.find((rank) => rank === data)
                        ? setEffect(effect.filter((rank) => rank !== data))
                        : setEffect((oldRank) => [...oldRank, data])
                    }}
                  >
                    <img
                      className="mr-2"
                      src={`../svg/effect/${capitalizeData(data)}.svg`}
                      alt=""
                    />
                    <div
                      className="text-[14px] font__M__plus font-bold"
                      style={{ color: colorTextEffect(data) }}
                    >
                      {t(`common.filter.effect.${data}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //quality
          {
            key: 'quality',
            label: t('common.filter.tag.quality'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {qualityData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] p-[10px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      quality?.find((rank) => rank === data) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      quality?.find((rank) => rank === data)
                        ? setQuality(quality.filter((rank) => rank !== data))
                        : setQuality((oldRank) => [...oldRank, data])
                    }}
                  >
                    <img
                      className="mr-2 w-[24px] h-[28px]"
                      src={`../svg/qualityTag/${data}.svg`}
                      alt=""
                    />
                    <div className="text-[14px] font__M__plus font-bold">
                      {t(`common.filter.quality.${data}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //mainSeed
          {
            key: 'mainSeed',
            label: t('common.filter.tag.mainSeed'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {mainSeedData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      mainSeedFilterRegeneration?.find(
                        (rank) => rank === data,
                      ) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      mainSeedFilterRegeneration?.find((rank) => rank === data)
                        ? setMainSeedFilterRegeneration(
                            mainSeedFilterRegeneration.filter(
                              (rank) => rank !== data,
                            ),
                          )
                        : setMainSeedFilterRegeneration((oldRank) => [
                            ...oldRank,
                            data,
                          ])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {data}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
        expandIcon={({ isActive }) => (
          <CaretUpOutlined
            style={{ fontSize: 16 }}
            rotate={isActive ? 0 : 180}
          />
        )}
      />
    </div>
  )
}
