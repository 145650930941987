export const CategoryData = [
  'Training_Item',
  'Enhance_Item',
  'Fusion_Item',
  'Regeneration_Item',
]

export const effectData = [
  'fatigue',
  'condition',
  'stress',
  // 'energy',
  'friendship',
  // 'nutrition',
  'body',
  'lifespan',
  'physical',
  // 'physicalBuild',
  // 'Ticket',
  'trainingPolicy',
  // 'bit',
  'rest',
  // 'farm',
  'seed',
  'trait',
  // 'basicParameter',
  'hp',
  'str',
  'int',
  'dex',
  'agi',
  'vit',
]

export const qualityData = ['R', 'UC', 'C']
export const qualityDataMyAsset = ['R', 'UC', 'C', 'SHOP', 'B']
export const colorTextEffect = (text) => {
  switch (text) {
    case 'HP':
      return '#66BB6A'
    case 'STR':
      return '#FE6F41'
    case 'INT':
      return '#9574CD'
    case 'DEX':
      return '#90C8F6'
    case 'AGI':
      return '#FEEB3A'
    case 'VIT':
      return '#4AAF4F'
    default:
      break
  }
}
