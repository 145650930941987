import { Menu } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import { getItemMenu } from '~/helpers/common'
import { ROUTE } from '~/views/routes/config'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'
import { CaretUpOutlined } from '@ant-design/icons'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'

export default function NavMenuData() {
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const { t } = useTranslation()
  const {
    data: { user },
  } = useTheme()
  const location = useLocation()
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  const handleMenuItemClick = () => {
    setIsSubMenuOpen(true)
  }

  const menuCommon = [
    getItemMenu(
      <div className={`flex relative navbar__left_element h-full w-full`}>
        {isMobile ? (
          <>
            <div className="mr-3" onClick={handleMenuItemClick}>
              {t(`layout.header.${ROUTE.MARKETPLACE.TITLE}`)}
            </div>
            <CaretUpOutlined
              style={{ fontSize: 16 }}
              rotate={180}
              onClick={handleMenuItemClick}
            />
          </>
        ) : (
          <span className="w-full relative" onClick={handleMenuItemClick}>
            {t(`layout.header.${ROUTE.MARKETPLACE.TITLE}`)}
          </span>
        )}
      </div>,
      'Marketplace',
      null,
      [
        getItemMenu(
          <Link
            to={ROUTE?.HOME.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.HOME.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.HOME.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.FARM.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.FARM.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.FARM.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.ITEM.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.ITEM.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.ITEM.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.HASH_NFT.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.HASH_NFT.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.HASH_NFT.PATH,
          null,
        ),
      ],
    ),
    getItemMenu(
      <div className={`flex relative navbar__left_element h-full w-full`}>
        {isMobile ? (
          <>
            <div className="mr-3" onClick={handleMenuItemClick}>
              {t(`layout.header.${ROUTE.SHOP.TITLE}`)}
            </div>
            <CaretUpOutlined
              style={{ fontSize: 16 }}
              rotate={180}
              onClick={handleMenuItemClick}
            />
          </>
        ) : (
          <span className="w-full relative" onClick={handleMenuItemClick}>
            {t(`layout.header.${ROUTE.SHOP.TITLE}`)}
          </span>
        )}
      </div>,
      'Shop',
      null,
      [
        getItemMenu(
          <Link
            to={ROUTE?.GENESIS_BOX.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.GENESIS_BOX.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.GENESIS_BOX.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.GENERAL_BOX.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.GENERAL_BOX.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.GENERAL_BOX.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.TRAINING_ITEM.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.TRAINING_ITEM.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.TRAINING_ITEM.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.FARM_SHOP.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.FARM_SHOP.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.FARM_SHOP.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.BIT.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">
                {t(`layout.header.${ROUTE.BIT.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.BIT.PATH,
          null,
        ),
      ],
    ),
    getItemMenu(
      <div className={`flex relative navbar__left_element h-full w-full`}>
        {isMobile ? (
          <>
            <Link
              to={ROUTE?.GAME.PATH}
              className={`navbar__left_element h-full w-full`}
            >
              <div className={`relative h-full w-full`}>
                <span className="w-full relative">
                  {t(`layout.header.game`)}
                </span>
              </div>
            </Link>
            {/* <a
              href="https://beta.remonster.world"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={`relative h-full w-full`}>
                <span className="w-full relative">
                  {t(`layout.header.game`)}
                </span>
              </div>
            </a> */}
            ,
          </>
        ) : (
          <Link
            to={ROUTE?.GAME.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={`relative h-full w-full`}>
              <span className="w-full relative">{t(`layout.header.game`)}</span>
            </div>
          </Link>
          // <a
          //   href="https://beta.remonster.world"
          //   target="_blank"
          //   rel="noopener noreferrer"
          // >
          //   <div className={`relative h-full w-full`}>
          //     <span className="w-full relative">{t(`layout.header.game`)}</span>
          //   </div>
          // </a>
        )}
      </div>,
      'Game',
      null,
    ),
    getItemMenu(
      <div className={`flex relative navbar__left_element h-full w-full`}>
        {isMobile ? (
          <>
            <a
              href="https://remonster.world"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-3"
            >
              {t(`layout.header.about`)}
            </a>
          </>
        ) : (
          <span className="w-full relative">
            <a
              href="https://remonster.world"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-3"
            >
              {t(`layout.header.about`)}
            </a>
          </span>
        )}
      </div>,
      'About',
      null,
    ),
  ]
  const menuCommonProfile = [
    ...menuCommon,
    getItemMenu(
      <Link
        to={ROUTE?.PROFILE_MONSTER_SALES.PATH}
        className={`navbar__left_element h-full w-full`}
      >
        <div className={`relative h-full w-full`}>
          <span className="w-full relative">{t(`layout.header.myPage`)}</span>
        </div>
      </Link>,
      'MyPage',
      null,
    ),
  ]

  const defaultSelectedKeyNavMenu = () => {
    if (
      location.pathname === ROUTE.HOME.PATH ||
      location.pathname === ROUTE.FARM.PATH ||
      location.pathname === ROUTE.ITEM.PATH ||
      location.pathname === ROUTE.HASH_NFT.PATH
    ) {
      return 'Marketplace'
    } else if (
      location.pathname === ROUTE.GENESIS_BOX.PATH ||
      location.pathname === ROUTE.GENERAL_BOX.PATH ||
      location.pathname === ROUTE.TRAINING_ITEM.PATH ||
      location.pathname === ROUTE.FARM_SHOP.PATH ||
      location.pathname === ROUTE.BIT.PATH
    ) {
      return 'Shop'
    } else if (
      location.pathname === ROUTE.PROFILE_MONSTER_SALES.PATH ||
      location.pathname === ROUTE.PROFILE_FARMS_SALES.PATH ||
      location.pathname === ROUTE.PROFILE_ITEMS_SALES.PATH ||
      location.pathname === ROUTE.PROFILE_HASH_SALES.PATH ||
      location.pathname === ROUTE.PROFILE_MONSTERS.PATH ||
      location.pathname === ROUTE.PROFILE_MEMORY_MONSTERS.PATH ||
      location.pathname === ROUTE.PROFILE_FARMS.PATH ||
      location.pathname === ROUTE.PROFILE_ITEMS.PATH ||
      location.pathname === ROUTE.PROFILE_HASH.PATH ||
      location.pathname === ROUTE.PROFILE_REWARDS.PATH ||
      location.pathname === ROUTE.PROFILE_BRIDGE.PATH ||
      location.pathname === ROUTE.PROFILE_TRADE_HISTORY.PATH ||
      location.pathname === ROUTE.PROFILE_ACCOUNT_SETTINGS.PATH
    ) {
      return 'MyPage'
    }
  }
  const pathActive = [
    ROUTE.MONSTER_DETAIL.MAIN_PATH,
    ROUTE.FARM_DETAIL.MAIN_PATH,
    ROUTE.ITEM_DETAIL.MAIN_PATH,
    ROUTE.HASH_NFT_DETAIL.MAIN_PATH,
    ROUTE.PROFILE_MONSTER_SALES.MAIN_PATH,
  ]

  const isRouteActive = pathActive.some((path) =>
    location.pathname.startsWith(path),
  )

  useEffect(() => {
    const savedSelectedKey = localStorage.getItem('selectedMenuKey')
    if (savedSelectedKey) {
      setDefaultSelectedKey(savedSelectedKey)
    }
  }, [])

  useEffect(() => {
    if (defaultSelectedKey) {
      localStorage.setItem('selectedMenuKey', defaultSelectedKey)
    }
  }, [])

  const getDefaultOpenKeys = () => {
    if (
      [
        ROUTE.HOME.PATH,
        ROUTE.FARM.PATH,
        ROUTE.ITEM.PATH,
        ROUTE.HASH_NFT.PATH,
      ].includes(location.pathname)
    ) {
      return [ROUTE.MARKETPLACE.PATH]
    } else if (
      [
        ROUTE.GENESIS_BOX.PATH,
        ROUTE.GENERAL_BOX.PATH,
        ROUTE.TRAINING_ITEM.PATH,
        ROUTE.FARM_SHOP.PATH,
        ROUTE.BIT.PATH,
      ].includes(location.pathname)
    ) {
      return [ROUTE.SHOP.PATH]
    }
    return []
  }

  const [defaultSelectedKey, setDefaultSelectedKey] = useState(
    isRouteActive ? '' : location.pathname,
  )
  return (
    <>
      <Menu
        className={`${!isMobile && 'ml-6'}  isActiveAbout`}
        style={{ minWidth: 530 }}
        mode={`${isMobile ? 'inline' : 'horizontal'}`}
        theme="light"
        triggerSubMenuAction={isSubMenuOpen ? 'click' : 'hover'}
        items={user ? menuCommonProfile : menuCommon}
        defaultOpenKeys={isMobile ? getDefaultOpenKeys() : ''}
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[defaultSelectedKeyNavMenu()]}
      />
    </>
  )
}
