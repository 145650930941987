import { ROUTE } from '~/views/routes/config'
import { ReMonsterTwoBorderSm } from '../../Button/ReMonsterTwoBorderSm'
import { useHistory, Link } from 'react-router-dom'
import { convertOasToUSD, formatNumber } from '~/helpers/common'
import { getImgByFarmTerrain } from '~/helpers/getValueByFarmTerrain'

export const FarmsCard = (props) => {
  const { data, button, tema } = props
  const history = useHistory()
  const handleLinkClick = (e) => {
    if (e.target.closest('.common__btn')) {
      e.preventDefault()
    }
  }
  return (
    <Link
      to={`${ROUTE.FARM_DETAIL.SUB_PATH}/${data?.farm_nft_id}`}
      onClick={handleLinkClick}
      className=" hover:text-[#FFFFFF]"
    >
      <div
        className="card__monster w-[250px] h-[350px] cursor-pointer"
        // onClick={(e) => {
        //   if (!e.target.closest('.common__btn')) {
        //     history.push(`${ROUTE.FARM_DETAIL.SUB_PATH}/${data?.farm_nft_id}`)
        //   }
        // }}
      >
        <div className="relative flex items-center justify-center h-full">
          <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
            <div
              className="h-full flex flex-col p-[10px] text-[18px]"
              style={{
                backgroundImage: `url(../imgs/farm/bgFarm/${getImgByFarmTerrain(
                  data?.farm_terrain,
                )}.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="flex items-start flex-col">
                <div className="title__card">{data?.farm_name ?? 'Farm'}</div>
                <div className="relative left-0 text-[11px] z-50">
                  <div
                    className=" text-[11px] font__M__plus font-bold min-w-[33px] h-[24px] items-center flex justify-center "
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      borderRadius: 4,
                    }}
                  >
                    {data ? `#${data?.farm_nft_id}` : ''}
                  </div>
                </div>
              </div>

              <div className="relative h-full flex items-center justify-center z-40">
                <img
                  alt=""
                  className="card__monster_img w-[160px] h-[160px] object-contain ease-in-out duration-300"
                  src={`../imgs/farm/${getImgByFarmTerrain(
                    data?.farm_terrain,
                  )}.png`}
                />
                <div className="absolute bottom-[-7px] left-0 ">
                  {`${data?.total_parameters}/${data?.maximumTotalParameter}`}
                </div>
              </div>
            </div>

            <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
              {data?.price && (
                <>
                  <div className="flex items-center justify-center">
                    <img
                      className="w-[16px] h-[16px] object-contain	"
                      src="../imgs/coins/oasis.svg"
                      alt=""
                    />
                    <div className="relative whitespace-nowrap flex ml-2">
                      {formatNumber(data?.price)}
                    </div>
                  </div>

                  <div className="text-[12px] mb-1">
                    ${formatNumber(convertOasToUSD(data?.price, tema?.tema))}
                  </div>
                </>
              )}

              <ReMonsterTwoBorderSm
                title={button?.title}
                data={data}
                functionButton={button?.functionButton}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
